import { CaretRightOutlined, UpCircleOutlined } from '@ant-design/icons';
import { Card, Collapse } from 'antd';
import imagePVI from 'assets/images/imagePVI.png';
import imagePVI1 from 'assets/images/imagePVI_1.png';
import imagePVI2 from 'assets/images/imagePVI_2.png';
import imagePVI3 from 'assets/images/imagepvi_3.png';
import BgrContainer from 'assets/images/istock-1140524580_3.jpg';
import PVI_Insurance_card from 'assets/images/PVI_Insurance_card.jpg';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const { Panel } = Collapse;

const PageXclaimInsurance = () => {
  const { t } = useTranslation(['PTIManagement']);

  const [showsScrolBtn, setShowScrolBtn] = useState(false);

  useEffect(() => {
    const handleButtonVisibility = () => {
      window.pageYOffset > 300 ? setShowScrolBtn(true) : setShowScrolBtn(false);
    };

    window.addEventListener('scroll', handleButtonVisibility);
    return () => {
      window.addEventListener('scroll', handleButtonVisibility);
    };
  }, []);

  const dieuKienThamGiaBH = [
    {
      title: '1. ĐỐI TƯỢNG ĐƯỢC BẢO HIỂM:',
      list: [
        {
          text: 'Cán bộ công nhân viên',
        },
        {
          text: 'Độ tuổi tham gia bảo hiểm: Từ đủ 18 tuổi đến hết 65 tuổi.',
        },
        {
          text: 'Người Việt Nam hoặc người nước ngoài sinh sống và làm việc trong lãnh thổ Việt Nam và không rời Việt Nam quá 180 ngày liên tục',
        },
      ],
    },
    {
      title: '2. KHÔNG BẢO HIỂM:',
      list: [
        {
          text: 'Người bị mắc bệnh tâm thần, động kinh..',
        },
        {
          text: 'Bị thương tật vĩnh viễn từ 80% trở lên tại thời điểm hiệu lực Hợp đồng',
        },
      ],
    },
  ];

  const quyenLoiBaoHiem = [
    {
      title: 'Tử vong,tàn tật toàn bộ vĩnh viễn do ốm đau, bệnh tật, thai sản',
      list: [
        {
          text: 'Tử vong do ốm đau, bệnh tật, thai sản',
        },
        {
          text: 'Tàn tật toàn bộ vĩnh viễn do ốm đau, bệnh tật, thai sản',
        },
      ],
    },
    {
      title: 'Tai nạn 24/24',
      list: [
        {
          text: 'Chết, thương tật toàn bộ vĩnh viễn do tai nạn',
        },
        {
          text: 'Thương tật bộ phận vĩnh viễn do tai nạn',
        },
        {
          text: 'Chi phí y tế do tai nạn',
        },
      ],
    },
    {
      title: 'Chi phí y tế do bệnh tật',
      list: [
        {
          text: 'Nằm viện, phẫu thuật, thai sản',
        },
        {
          text: 'Điều trị ngoại trú',
        },
        {
          text: 'Điều trị răng (bao gồm trong điều trị ngoại trú)',
        },
      ],
    },
  ];
  const loaiTruChung = [
    'Cai nghiện ma túy, cai nghiện rượu, sử dụng thuốc không theo chỉ định của Cơ sở y tế/ Bác sĩ',
    'Bất kỳ ốm đau, bệnh tật, thương tích hay chết nào gây ra bởi chiến tranh, nội chiến, đình công, khủng bố',
    'Hành động cố ý của NĐBH hoặc người thừa kế hợp pháp',
    'NĐBH có ý định tự tử hoặc cố ý dấn thân vào nơi nguy hiểm không cần thiết trừ khi có ý định cứu người, cứu tài sản',
    'Thương tật của Người được bảo hiểm phát sinh từ đánh lộn trừ phi chứng minh được đó là do phải bảo vệ tính mạng của bản thân',
    'Vi phạm nghiêm trọng pháp luật',
    'Người được bảo hiểm bị ảnh hưởng của rượu, bia, ma tuý hoặc các chất kích thích tương tự khác',
    'Điều trị tất cả các loại bệnh tâm thần hoặc rối loạn tâm thần',
    'Điều trị liên quan đến các bệnh lây lan qua đường tình dục (lậu, giang mai…), HIV',
    'Bất kỳ ốm đau, bệnh tật, thương tích hay chết nào phát sinh trực tiếp hoặc gián tiếp từ ô nhiễm hoá học, chất phóng xạ,nguyên liệu hạt nhân hay thiên tai như: động đất, núi lửa gây ra',
    'Điều trị phục hồi chức năng, duy trì hay chỉnh sửa các bộ phận hoặc dụng cụ giả được lắp đặt từ bên ngoài, các bộ phận giả, răng giả, nạng chống, xe đẩy…',
  ];

  const baoHiemTaiNan = [
    {
      stt: '',
      name1: 'Giới hạn lãnh thổ',
      name2: 'Toàn cầu',
      name3: 'Toàn cầu',
      isBold: true,
    },
    {
      stt: '1',
      name1: 'Tử vong hoặc thương tật toàn bộ vĩnh viển',
      name2: '70.000.000',
      name3: '50.000.000',
    },
    {
      stt: '',
      name1: 'Thương tật bộ phận vĩnh viễn',
      name2: 'Tỷ lệ phần trăm dựa theo bảng tỷ lệ thương tật',
      name3: 'Tỷ lệ phần trăm dựa theo bảng tỷ lệ thương tật',
    },
    {
      stt: '2',
      name1: 'Trợ cấp ngày nghỉ do điều trị tai nạn thực tế theo chỉ định của bác sĩ',
      name2: (
        <>
          <p>VND 200.000/ ngày</p>
          <p>(90 ngày/ năm)</p>
        </>
      ),
      name3: (
        <>
          <p>VND 100.000/ ngày</p>
          <p>(90 ngày/ năm)</p>
        </>
      ),
    },
    {
      stt: '3',
      name1: (
        <>
          <p>Chi phí y tế do tai nạn (Giới hạn lãnh thổ: Việt Nam)</p>
          <p>
            Trong suốt thời gian điều trị do tai nạn bao gồm chi phí vận chuyển cấp cứu (không bao
            gồm vận chuyển bằng đường hàng không)
          </p>
        </>
      ),
      name2: '50.000.000/ năm',
      name3: '30.000.000/ năm',
    },
  ];

  const loaiTruTuVongBenhVaThaiSan = [
    '1. Tự tử',
    '2. HIV/AIDS',
    '3. Thực hiện hoặc mưu toan hành vi phạm tội',
  ];

  const baoHiemTuVong = [
    {
      stt: '',
      name1: 'Giới hạn lãnh thổ',
      name2: 'Việt Nam',
      name3: 'Việt Nam',
    },
    {
      stt: '1',
      name1: 'Tử vong hoặc tàn tật toàn bộ vĩnh viển do ốm đau, bệnh tật, thai sản',
      name2: '70.000.000',
      name3: '50.000.000',
    },
  ];

  const baoHiemChiPhiYTe = [
    { stt: '', name1: 'Giới hạn lãnh thổ', name2: 'Việt Nam', name3: 'Việt Nam' },
    {
      stt: '',
      name1: (
        <>
          <p>Giới hạn tối đa/Người/Năm</p>
          <p>(Giới hạn chung, bao gồm các giới hạn phụ dưới đây)</p>
        </>
      ),
      name2: '189.000.000',
      name3: '150.000.000',
    },
    {
      stt: '1',
      name1: (
        <>
          <p>
            - Chi phí tiền phòng nằm viện (Tối đa 60 ngày/ năm): chi phí tiền phòng giới hạn tới
            phòng tiêu chuẩn là phòng đơn có giá thấp nhất tại bệnh viện. Loại trừ phòng VIP, Bao
            phòng
          </p>
          <p>- Xét nghiệm hoặc chuẩn đoán bằng hình ảnh</p>
          <p>- Thuốc điều trị</p>
          <p>- Chi phí đỡ đẻ</p>
          <p>- Các chi phí y tế khác trong thời gian nằm viện</p>
          <p>(Áp dụng cho cả trường hợp điều trị trong ngày)</p>
        </>
      ),
      name2: (
        <>
          <p>3.150.000/ ngày</p>
          <p>Tối đa: 63.000.000 VND</p>
        </>
      ),
      name3: (
        <>
          <p>2.500.000/ ngày</p>
          <p>Tối đa: 50.000.000 VND</p>
        </>
      ),
    },
    {
      stt: '2',
      name1: (
        <>
          <p>
            Chi phí phẫu thuật (bao gồm phẫu thuật nội trú, phẫu thuật ngoại trú và phẫu thuật trong
            ngày)
          </p>
          <p>
            Cấy ghép nội tạng (Không bảo hiểm cho chi phí mua các bộ phận nội tạng và chi phí hiến
            nội tạng)
          </p>
        </>
      ),
      name2: (
        <>
          <p>Tối đa: 63.000.000 VND</p>
        </>
      ),
      name3: (
        <>
          <p>Tối đa: 50.000.000 VND</p>
        </>
      ),
    },
    {
      stt: '3',
      name1: (
        <>
          <p>Điều trị cấp cứu</p>
        </>
      ),
      name2: (
        <>
          <p>Tối đa: 63.000.000 VND</p>
        </>
      ),
      name3: (
        <>
          <p>Tối đa: 50.000.000 VND</p>
        </>
      ),
    },
    {
      stt: '4',
      name1: (
        <>
          <p>Chi phí trước nhập viện (Trong vòng 30 ngày trước khi nhập viện)</p>
        </>
      ),
      name2: (
        <>
          <p>3.150.000/ năm</p>
        </>
      ),
      name3: (
        <>
          <p>2.500.000/ năm</p>
        </>
      ),
    },
    {
      stt: '5',
      name1: (
        <>
          <p>Điều trị sau khi xuất viện (Trong vòng 30 ngày trước khi xuất viện)</p>
        </>
      ),
      name2: (
        <>
          <p>3.150.000/ năm</p>
        </>
      ),
      name3: (
        <>
          <p>2.500.000/ năm</p>
        </>
      ),
    },
    {
      stt: '6',
      name1: (
        <>
          <p>Chi phí y tá chăm sóc tại nhà ngay sau khi xuất hiện (Tối đa 15 ngày/ năm)</p>
        </>
      ),
      name2: (
        <>
          <p>3.150.000/ năm</p>
        </>
      ),
      name3: (
        <>
          <p>2.500.000/ năm</p>
        </>
      ),
    },
    {
      stt: '7',
      name1: (
        <>
          <p>Phục hồi chức năng</p>
        </>
      ),
      name2: (
        <>
          <p>6.300.000/ năm</p>
        </>
      ),
      name3: (
        <>
          <p>5.000.000/ năm</p>
        </>
      ),
    },
    {
      stt: '8',
      name1: (
        <>
          <p>Dịch vụ xe cứu thương</p>
          <p>
            (Cho dịch vụ cứu thương của địa phương với mục đích vận chuyển khẩn cấp người được bảo
            hiểm tới cơ sở y tế gần nhất có khả năng cung cấp dịch vụ y tế thích hợp, loại trừ đường
            hàng không. Trong trường hợp không có dịch vụ cứu thương của địa phương người được bảo
            hiểm có thể dùng taxi với giới hạn trách nhiệm tới 200.000 VND/ vụ. Hóa đơn taxi phải
            được cung cấp với thông tin liên quan)
          </p>
        </>
      ),
      name2: (
        <>
          <p>Tối đa: 63.000.000 VND</p>
        </>
      ),
      name3: (
        <>
          <p>Tối đa: 50.000.000 VND</p>
        </>
      ),
    },
    {
      stt: '9',
      name1: (
        <>
          <p>Trợ cấp nằm viện/ ngày</p>
          <p>(Tối đa 60 ngày/ năm)</p>
        </>
      ),
      name2: (
        <>
          <p>63.000/ ngày</p>
        </>
      ),
      name3: (
        <>
          <p>50.000/ ngày</p>
        </>
      ),
    },
    {
      stt: '10',
      name1: (
        <>
          <p>
            Trợ cấp mai táng (Chỉ áp dụng trong trường hợp tử vong tại bệnh viện với điều kiện có
            chi phí y tế phát sinh trước khi tử vong)
          </p>
        </>
      ),
      name2: (
        <>
          <p>120.000/ ngày</p>
        </>
      ),
      name3: (
        <>
          <p>100.000/ ngày</p>
        </>
      ),
    },
    {
      stt: '11',
      name1: (
        <>
          <p>Quyền lợi thai sản:</p>
        </>
      ),
      name2: (
        <>
          <p>30.000.000</p>
        </>
      ),
      name3: (
        <>
          <p>30.000.000</p>
        </>
      ),
    },
    {
      stt: '',
      name1: (
        <>
          <p>- Sinh thường</p>
          <p>- Biến chứng thai sản</p>
        </>
      ),
      name2: (
        <>
          <p>Theo giới hạn phụ 1 - Phần 2A</p>
        </>
      ),
      name3: (
        <>
          <p>Theo giới hạn phụ 1 - Phần 2A</p>
        </>
      ),
    },
    {
      stt: '',
      name1: (
        <>
          <p>- Sinh mổ</p>
        </>
      ),
      name2: (
        <>
          <p>Theo giới hạn phụ 1 và 2 - Phần 2A</p>
        </>
      ),
      name3: (
        <>
          <p>Theo giới hạn phụ 1 và 2 - Phần 2A</p>
        </>
      ),
    },
    {
      stt: '12',
      name1: (
        <>
          <p>
            Chi phí điều trị bệnh cấp tính cho em bé sau sinh (Trong vòng 7 ngày sau sinh) với điều
            kiện mẹ chưa xuất viện
          </p>
        </>
      ),
      name2: (
        <>
          <p>600.000</p>
        </>
      ),
      name3: (
        <>
          <p>500.000</p>
        </>
      ),
    },
  ];

  const baoHiemNgoaiTruVaRang = [
    {
      stt: '',
      name1: 'Giới hạn lãnh thổ',
      name2: 'Việt Nam',
      name3: 'Việt Nam',
    },
    {
      stt: '',
      name1: (
        <>
          <p>Giới hạn tối đa/Người/Năm</p>
          <p>(Giới hạn chung, bao gồm các giới hạn phụ dưới đây)</p>
        </>
      ),
      name2: '8.000.000',
      name3: '6.000.000',
    },
    {
      stt: '1',
      name1: (
        <>
          <p>- Chi phí khám bệnh</p>
          <p>- Tiền thuốc theo kê đơn của bác sĩ</p>
          <p>
            - Chi phí xét nghiệm, chuẩn đoán hình ảnh trong việc điều trị bệnh thuộc phạm vi bảo
            hiểm
          </p>
        </>
      ),
      name2: (
        <>
          <p>2.400.000/ lần</p>
          <p>Không giới hạn số lần khám</p>
        </>
      ),
      name3: (
        <>
          <p>1.800.000/ lần</p>
          <p>Không giới hạn số lần khám</p>
        </>
      ),
    },
    {
      stt: '2',
      name1: (
        <>
          <p>
            Vật lý trị liệu, trị liệu học bức xạ, liệu pháp ánh sáng và các phương pháp điều trị
            tương tự khác do bác sĩ chỉ định
          </p>
        </>
      ),
      name2: (
        <>
          <p>700.000/ năm</p>
        </>
      ),
      name3: (
        <>
          <p>700.000/ năm</p>
        </>
      ),
    },
    {
      stt: '3',
      name1: (
        <>
          <p>Khám thai định kỳ</p>
        </>
      ),
      name2: (
        <>
          <p>800.000/ năm</p>
        </>
      ),
      name3: (
        <>
          <p>600.000/ năm</p>
        </>
      ),
    },
    {
      stt: '4',
      name1: (
        <>
          <p>Điều trị răng cơ bản</p>
          <p>- Khám, chụp X Quang răng</p>
          <p>- Lấy cao răng (1 lần/ năm)</p>
          <p>- Nhổ răng bệnh lý (bao gồm tiểu phẩu, điều trị tủy răng)</p>
          <p>- Điều trị viêm nướu (lợi), nha chu</p>
          <p>- Trám (hàn) răng bằng các chất liệu thông thường (amalgam hoặc composite)</p>
        </>
      ),
      name2: (
        <>
          <p>2.400.000/ năm</p>
        </>
      ),
      name3: (
        <>
          <p>1.800.000/ năm</p>
        </>
      ),
    },
  ];

  const luuYDieuTriRang = [
    '1. Đối với Quyền lợi điều trị răng: BH PVI chỉ chi trả cho khách hàng khi khách hàng khám và điều trị nha khoa tại các cơ sở y tế Công lập và/ hoặc các cơ sở y tế trong hệ thống bảo lãnh hiện hành của BH PVI',
    '2. Đối với các bệnh viện, phòng khám, nhà thuốc từ chối chi trả của PVI: PVI sẽ không chi trả các chi phí y tế khi NĐBH khám và điều trị tại các bệnh viện, phòng khám, nhà thuốc nằm trong danh sách từ chối chi trả của Công ty Bảo Hiểm PVI',
    '3. Danh sách các bệnh viện, phòng khám trong danh sách bảo lãnh; danh sách các bệnh viện, phòng khám, nhà thuốc bị từ chối chi trả của PVI có thể thay đổi',
    '4. Đối với phòng khám tư: Toa thuốc phải có đầy đủ thông tin của phòng khám (tên phòng khám, địa chỉ phòng khám) và phải được đóng dấu mộc vuông của phòng khám tư. Trong trường hợp phòng khám tư không có con dấu vuông thì phải bổ sung giấy phép đăng ký mở phòng khám trong hồ sơ yêu cầu bồi thường',
  ];

  const thoiGianCho = [
    'Đối với Nhân Viên & Người Thân đã tham gia HĐBH cũ 2023 và chờ đủ 1 năm: Không áp dụng thời gian chờ, trong đó quyền lợi thai sản bồi thường đầy đủ',
    'Đối với Nhân Viên & Người Thân đã tham gia HĐBH cũ 2023 và chờ chưa đủ 1 năm: Chờ tiếp theo thời gian chờ đối với từng quyền lợi kể từ ngày tham gia, trong đó',
  ];

  const thoiGianChoNhanVien = [
    {
      name: 'Điều trị Bệnh thông thường',
      apply: 'Miễn chờ',
    },
    {
      name: 'Bồi thường theo tỷ lệ sảy thai/ hư thai/ Biến chứng thai sản',
      apply: '60 ngày',
    },
    {
      name: 'Bồi thường theo tỷ lệ sinh đẻ',
      apply: '270 ngày',
    },
    {
      name: 'Điều trị Bệnh đặc biệt, bệnh có sẵn, bệnh mãn tính',
      apply: 'Miễn chờ',
    },
    {
      name: 'Tử vong hoặc tàn tật toàn bộ vĩnh viễn do ốm bệnh thông thường',
      apply: '30 ngày',
    },
    {
      name: 'Tử vong hoặc tàn tật toàn bộ vĩnh viễn do thai sản',
      apply: '270 ngày',
    },
    {
      name: 'Tử vong hoặc tàn tật toàn bộ vĩnh viễn do Bệnh đặc biệt, bệnh có sẵn, bệnh mãn tính',
      apply: '365 ngày',
    },
  ];

  const thoiGianChoNguoiThan = [
    {
      name: 'Điều trị Bệnh thông thường',
      apply: '30 ngày',
    },
    {
      name: 'Bồi thường theo tỷ lệ sảy thai/ hư thai/ Biến chứng thai sản',
      apply: '60 ngày',
    },
    {
      name: 'Bồi thường sinh đẻ',
      apply: '270 ngày',
    },
    {
      name: 'Điều trị Bệnh đặc biệt, bệnh có sẵn, bệnh mãn tính',
      apply: '365 ngày',
    },
    {
      name: 'Tử vong hoặc tàn tật toàn bộ vĩnh viễn do ốm bệnh thông thường',
      apply: '30 ngày',
    },
    {
      name: 'Tử vong hoặc tàn tật toàn bộ vĩnh viễn do thai sản',
      apply: '270 ngày',
    },
    {
      name: 'Tử vong hoặc tàn tật toàn bộ vĩnh viễn do Bệnh đặc biệt, bệnh có sẵn, bệnh mãn tính ',
      apply: '365 ngày',
    },
  ];

  const loaiTruDoiVoiBenh = [
    'Vitamin/ khoáng chất/ thực phẩm chức năng, các loại thuốc bổ sung cho chế độ dinh dưỡng, ăn kiêng bao gồm cả các sản phẩm làm đẹp.',
    'Điều trị các bệnh liên quan đến trở ngại về đường hô hấp: ngủ ngáy, ngừng thở khi ngủ',
    'Bất kỳ hình thức điều trị hoặc thuốc nào dựa vào thí nghiệm hoặc chưa được chứng minh dựa trên kiến thức y học được chấp nhận rộng rãi',
    'Điều dưỡng, an dưỡng',
    'Kiểm tra sức khỏe mà không liên quan đến điều trị bệnh tật.',
    'Điều trị hoặc phẫu thuật theo yêu cầu của Người được bảo hiểm và không liên quan đến việc điều trị và phẫu thuật bình thường do ngành y tế qui định',
    'Điều trị hoặc phẫu thuật các bệnh/ dị tật bẩm sinh hoặc di truyền',
    'Khám sức khoẻ định kỳ/ khám phụ khoa/ nam khoa/ chăm sóc trẻ sơ sinh/ tiêm chủng/ uống vắc xin phòng ngừa/ kiểm tra thị lực và thính lực thông thường/ điều trị suy thoái thị lực/ điều trị cận thị, viễn thị, loạn thị và những phẫu thuật sửa chữa những khiếm khuyết về suy thoái thị lực và thính lực tự nhiên',
    'Giải phẫu thẩm mỹ/ phẫu thuật tạo hình',
    'Chi phí phục hồi chức năng hoặc duy trì các bộ phận giả, răng giả',
    'Chăm sóc trước và sau khi sinh',
    'Kế hoạch hóa/ điều trị vô sinh, bất lực, rối loạn chức năng sinh dục, thụ tinh nhân tạo, liệu pháp thay đổi hóc môn, thay đổi giới tính',
    'Các bệnh lao, bệnh phong, sốt rét',
  ];

  const baoLanhVienPhi = [
    {
      name: 'NGƯỜI ĐƯỢC BH',
      list: ['Xuất trình thẻ PVI Care + CMND hoặc Giấy Khai Sinh nếu là trẻ con'],
    },
    {
      name: 'BỆNH VIỆN',
      list: ['Kiểm tra thẻ và gởi yêu cầu bồi thường cho PVI'],
    },
    {
      name: 'PVI',
      list: [
        'Kiểm tra thông tin NĐBH và xác nhận bảo lãnh viện phí',
        'Thanh toán trực tiếp cho bệnh viện',
      ],
    },
  ];

  const benhVienBaoLanh = [
    {
      name: 'HỒ CHÍ MINH',
      address: '',
      phone: '',
      time: '',
      isHeader: true,
    },
    {
      name: 'Bệnh viện Đại học Y Dược TP.HCM',
      address: '251 Hồng Bàng, Quận 5',
      phone: '028 3855 4269',
      time: (
        <>
          <p>24/7</p>
          <p>Đóng tạm ứng theo yêu cầu của BV</p>
        </>
      ),
    },
    {
      name: 'Bệnh viện Đại Học Y Dược - TP HCM cơ sở 2 (*)',
      address: '201 Nguyễn Chí Thanh, Phường 12, Quận 5',
      phone: '028 3857 0757',
      time: (
        <>
          <p>T2-T6:06h30-11h00, 13h00-16h00</p>
          <p>T7: 7h00-11h00</p>
        </>
      ),
    },
    {
      name: 'Bệnh viện Đại Học Y Dược - TP HCM cơ sở 3 (*)',
      address: '2218 Hoàng Văn Thụ, Quận Phú Nhuận',
      phone: '028 3844 2756',
      time: (
        <>
          <p>T2-T6: 07h-10h30, 13h00-15h30</p>
          <p>T7: 7h00-10h30</p>
        </>
      ),
    },
    {
      name: 'Bệnh viện Y Học Cổ Truyền (*)',
      address: '179 Nam Kỳ Khởi Nghĩa, Quận 3',
      phone: '028 3932 6004',
      time: (
        <>
          <p>T2-T6: 07h00-11h15, 13h00-16h10</p>
        </>
      ),
    },
    {
      name: 'Bệnh viện Tai Mũi Họng TP HCM (*)',
      address: '155B Trần Quốc Thảo, P9, Quận 3',
      phone: '028 3931 7381',
      time: (
        <>
          <p>T2-T6: 08h00-10h00, 13h00-15h00</p>
        </>
      ),
    },
    {
      name: 'Bệnh viện Từ Dũ - Nội trú',
      address: '284 Cống Quỳnh, P. PNL, Quận 1',
      phone: '028 3839 5117',
      time: (
        <>
          <p>24/7</p>
          <p>Đông tạm ứng theo yêu cầu của BV</p>
        </>
      ),
    },
    {
      name: 'Bệnh viện Từ Dũ - Ngoại trú (*)',
      address:
        'Phòng 102, lầu 1, Khu khám bệnh- Số 191 Nguyễn Thị |Minh Khai, P.Phạm Ngũ Lão, Q.1, Tp HCM',
      phone: '028 3839 5117',
      time: (
        <>
          <p>T2-T6: 07h00-9h30, 13h00-15h00</p>
          <p>T7: 07h00-9h30</p>
        </>
      ),
    },
    {
      name: 'Bệnh viện Mắt TP HCM (*)',
      address:
        'Khu khám mắt Khoa kỹ thuật cao - số 58 Bà Huyện Thanh Quan, Phường 7, Quận 3, TP Hồ Chí Minh',
      phone: '028 3932 6732',
      time: (
        <>
          <p>T2-T6: 7h00-10h00, 13h30-15h00</p>
        </>
      ),
    },
    {
      name: 'Bệnh viện Nhi Đồng 2 (*)',
      address: '33 Nguyễn Du, Quận 1, HCM (Khoa khám bệnh tòa nhà mới)',
      phone: '028 3829 8385',
      time: (
        <>
          <p>T2-CN: 7h00-10h30, 13h00-15h30, 16h00-18h30</p>
        </>
      ),
    },
    {
      name: 'Bệnh viện Răng Hàm Mặt TP HCM (*)',
      address: '263-265 Trần Hưng Đạo, P. CG, Quận 1',
      phone: '028 3836 0191',
      time: (
        <>
          <p>T2-T6: 07h30 15h00</p>
          <p>T7: 07h30-10h00</p>
        </>
      ),
    },
    {
      name: 'Bệnh viện Hùng Vương',
      address:
        '128 Hồng Bảng, Phường 12, Quận 5, Tp Hồ Chí Minh * Địa điểm tiếp nhận thẻ PVI tại Khu A - Khoa sanh (Khu vực nhận sanh) và khoa cấp cứu.',
      phone: '028 3855 8532',
      time: (
        <>
          <p>24/7</p>
          <p>Đóng tạm ứng theo yêu cầu của BV</p>
        </>
      ),
    },
    {
      name: 'Bệnh viện Nhi đồng Thành phố (*)',
      address: (
        <>
          <p>Số 15 Đường Võ Trần Chi, Ấp 1, Xã Tân Kiên, Huyện Bình Chánh, TP. HCM.</p>
          <p>*Địa điểm tiếp nhận thẻ PVI tại Quầy thông tin của Bệnh viện</p>
        </>
      ),
      phone: '028 2253 6688',
      time: (
        <>
          <p>T2-6: 07h00 - 11h00, 13h00-16h00</p>
          <p>T7: 07h00-11h00</p>
          <p>Đóng tạm ứng ngoại trú: 500.000 đ/ lần khám. Tạm ứng nội trú theo yêu cầu BV</p>
        </>
      ),
    },
    {
      name: 'Bệnh viện Đa Khoa An Sinh (*)',
      address: 'Số 10 Trần Huy Liệu, Quận Phú Nhuận',
      phone: '028 3845 7777',
      time: (
        <>
          <p>T2-6: 07h30-10h, 13h30-15h</p>
          <p>T7:07h30-10h</p>
        </>
      ),
    },
    {
      name: 'Bệnh viện Phụ San Mê Kông',
      address: '243-243A-243B Hoàng Văn Thụ, Quận Tân Bình',
      phone: '028 3844 2986',
      time: (
        <>
          <p>24/7</p>
          <p>Đóng tạm ứng theo yêu cầu BV</p>
        </>
      ),
    },
    {
      name: 'HÀ NỘI',
      address: '',
      phone: '',
      time: '',
      isHeader: true,
    },
    {
      name: 'Bệnh viện Hữu Nghị - Khoa Khám chữa bệnh theo Yêu cầu (*)',
      address: 'Số 1 Trần Khánh Dư, Hai Bà Trưng, Hà Nội',
      phone: '024 3822 1937',
      time: (
        <>
          <p>Thứ 2-6: 8h-17h</p>
          <p>Đóng tạm ứng theo yêu cầu BV</p>
        </>
      ),
    },
    {
      name: 'Bệnh viện Đại học Y Hà Nội',
      address: 'Tòa nhà A2, Số 1 Tôn Thất Tùng, quận Đống Đa',
      phone: '024 3574 7788',
      time: (
        <>
          <p>Thứ 2-6: 8h-17h</p>
          <p>Đóng tạm ứng theo yêu cầu BV</p>
        </>
      ),
    },
    {
      name: 'Bệnh viện Bệnh Nhiệt Đới Trung ương - Khoa khám bệnh theo yêu cầu (*)',
      address: 'Số 78 Đường Giải Phóng, quận Đống Đa',
      phone: '024 3576 5990',
      time: (
        <>
          <p>Thứ 2-6: 8h-16h30</p>
          <p>Đóng tạm ứng theo yêu cầu BV</p>
        </>
      ),
    },
    {
      name: 'Bệnh viện Y Học Cổ Truyền Trung ương (*)',
      address: '29 Nguyễn Binh Khiêm, quận Hai Bà Trưng, Hà Nội',
      phone: '024 3826 3616',
      time: (
        <>
          <p>Thứ 2-6: 7h30h-16h30h</p>
          <p>Đóng tạm ứng theo yêu cầu BV</p>
        </>
      ),
    },
    {
      name: 'Bệnh viện đa khoa Xanh-pôn - Trung tâm kỹ thuật cao và Tiêu hóa (*)',
      address: '12 Chu Văn An, Điện Biên, Ba Đình, Hà Nội',
      phone: '024 3823 3075',
      time: (
        <>
          <p>Thứ 2-6: 8h-16h</p>
          <p>Đóng tạm ứng theo yêu cầu BV</p>
        </>
      ),
    },
    {
      name: 'Bệnh viện Giao thông vận tải Trung ương (*)',
      address: 'Ngõ 1194 Đường Láng, quận Đống Đa',
      phone: '024 3766 4751',
      time: (
        <>
          <p>Thứ 2-6: 8h-16h</p>
          <p>Đóng tạm ứng theo yêu cầu BV</p>
        </>
      ),
    },
    {
      name: 'Bệnh Viện Nhi Trung ương - Khoa Quốc tế S (*)',
      address: '18/879 Đường Đê La Thành, quận Đống Đa',
      phone: '024 6273 1090',
      time: (
        <>
          <p>Thứ 2-6: 8h00-11h30; 13h30-16h30</p>
          <p>Đóng tạm ứng theo yêu cầu BV</p>
        </>
      ),
    },
    {
      name: 'Bệnh Viện Bưu Điện (*)',
      address: 'Số 49 Trần Điền, quận Hoàng Mai',
      phone: '024 3640 2262',
      time: (
        <>
          <p>Thứ 2-6: 8h-16h</p>
          <p>Đóng tạm ứng theo yêu cầu BV</p>
        </>
      ),
    },
    {
      name: 'Bệnh viện HN Việt Đức - Khoa Điều trị theo yêu cầu IC',
      address: 'Số 8 Phủ Doãn, quận Hoàn Kiếm',
      phone: '024 3828 9402',
      time: (
        <>
          <p>Thứ 2-6: 8h-16h</p>
          <p>Đóng tạm ứng theo yêu cầu BV</p>
        </>
      ),
    },
    {
      name: 'Bệnh viện Răng Hàm Mặt Trung ương Hà Nội (*)',
      address: '40 Tràng Thi, quận Hoàn Kiếm',
      phone: '024 3828 5347',
      time: (
        <>
          <p>Thứ 2-6: 8h-16h30</p>
          <p>Đóng tạm ứng theo yêu cầu BV</p>
        </>
      ),
    },
    {
      name: 'Bệnh viện Nội Tiết Trung Ương (*)',
      address: 'Ngõ 215, đường Ngọc Hồi, thị trấn Văn Điển, Thanh Trì, Hà Nội',
      phone: '024 6288 5152',
      time: (
        <>
          <p>Thứ 2-6: 8h-16h</p>
          <p>Đóng tạm ứng theo yêu cầu BV</p>
        </>
      ),
    },
    {
      name: 'Bệnh viện Nội Tiết Trung Ương cơ sở 2 (*)',
      address: 'Khu B, Yên Lãng, Thái Thịnh, Đống Đa, Hà Nội',
      phone: '024 3853 3527',
      time: (
        <>
          <p>Thứ 2-6: 8h-16h</p>
          <p>Đóng tạm ứng theo yêu cầu BV</p>
        </>
      ),
    },
    {
      name: (
        <>
          <p>Bệnh viện Phụ sản Hà Nội</p>
          <p>(Khu Điều trị Tự nguyện 1, Khu Điều trị Tự nguyện 2 và Khu B2)</p>
        </>
      ),
      address: 'Đường La Thành, Phường Ngọc Khánh, quận Ba Đình, Hà Nội',
      phone: '024 3775 1778',
      time: (
        <>
          <p>Thứ 2- 6: 8h-11h; 14h-16h</p>
          <p>Đóng tạm ứng theo yêu cầu BV</p>
        </>
      ),
    },
    {
      name: 'Bệnh viện Đa khoa quốc tế Thu Cúc (*)',
      address: 'Số 286 Thụy Khuê, Tây Hồ, Hà Nội',
      phone: '024 3835 5555',
      time: (
        <>
          <p>Thứ 2-CN: 08h00-20h00</p>
          <p>Đóng tạm ứng theo yêu cầu BV</p>
        </>
      ),
    },
    {
      name: 'Bệnh viện Đa khoa Quốc tế Vinmec',
      address: '458 Minh Khai, Phường Vĩnh Tuy, Hai Bà Trưng, Hà Nội',
      phone: '024 3974 3556',
      time: (
        <>
          <p>Thứ 2-6: 08h00-17h</p>
          <p>Đóng tạm ứng theo yêu cầu BV</p>
        </>
      ),
    },
    {
      name: 'Bệnh viện đa khoa Hồng Ngọc cơ sở 1 (*)',
      address: '55 Yên Ninh, quận Ba Đình',
      phone: '024 7305 8880',
      time: (
        <>
          <p>Thứ 2-CN: 07h30-17h</p>
          <p>Đóng tạm ứng theo yêu cầu BV</p>
        </>
      ),
    },
    {
      name: 'Bệnh viện đa khoa Hồng Ngọc cơ sở 2 (*)',
      address: 'Khu B1 và Tầng 10, Keangnam Landmark 72, Phạm Hùng, Hà Nội',
      phone: '024 7305 8880',
      time: (
        <>
          <p>Thứ 2-CN: 07h30-17h</p>
          <p>Đóng tạm ứng theo yêu cầu BV</p>
        </>
      ),
    },
    {
      name: 'HẢI PHÒNG',
      address: '',
      phone: '',
      time: '',
      isHeader: true,
    },
    {
      name: 'Bệnh viện Đại học Y Hải Phòng (*)',
      address: 'Số 225C Lạch Tray, Quận Ngô Quyền, Hải Phòng',
      phone: '0225 373 5696',
      time: (
        <>
          <p>Thứ 2-6: 7h30-16h30</p>
          <p>Đóng tạm ứng theo yêu cầu của BV</p>
        </>
      ),
    },
    {
      name: 'THÁI NGUYÊN',
      address: '',
      phone: '',
      time: '',
      isHeader: true,
    },
    {
      name: 'Bệnh viện Đa Khoa Trung Ương Thái Nguyên (*)',
      address: '479 Lương Ngọc Quyến, Tp.Thái Nguyên, Tỉnh Thái Nguyên',
      phone: '0208 3851 348',
      time: (
        <>
          <p>Thứ 2-6: 7h-11h30, 13h30-17h</p>
          <p>Đóng tạm ứng theo yêu cầu của BV</p>
        </>
      ),
    },
    {
      name: 'THANH HÓA',
      address: '',
      phone: '',
      time: '',
      isHeader: true,
    },
    {
      name: 'Bệnh viện Đa Khoa Tỉnh Thanh Hóa (*)',
      address: 'Số 181 Hải Thượng Làn Ông, phường Đông Vệ, thành phố Thanh Hóa, tỉnh Thanh Hóa',
      phone: '0237 3950 325',
      time: (
        <>
          <p>Thứ 2-6: 7h-11h30, 13h30-17h30</p>
          <p>Đóng tạm ứng theo yêu cầu của BV</p>
        </>
      ),
    },
    {
      name: (
        <>
          <p>Bệnh viện Đa Khoa Hợp Lực (*)</p>
          <p>
            <i style={{ color: 'red' }}>Dừng bảo lãnh từ ngày 07/02/2019</i>
          </p>
        </>
      ),
      address: '595 Nguyễn Chí Thanh, Đông Thọ, TP.Thanh Hóa',
      phone: '0237 3713 713',
      time: (
        <>
          <p>Thứ 2-7: 7h-11h30, 13h-16h30</p>
          <p>Đóng tạm ứng theo yêu cầu của BV</p>
        </>
      ),
    },
    {
      name: 'VINH',
      address: '',
      phone: '',
      time: '',
      isHeader: true,
    },
    {
      name: 'Bệnh viện Quốc Tế Vinh (*)',
      address: 'Số 99 Phạm Đình Toái, thành phố Vinh, tỉnh Nghệ An',
      phone: '0238 860 6679',
      time: (
        <>
          <p>Thứ 2-7: 7h-12h00, 13h-16h00</p>
          <p>Đóng tạm ứng theo yêu cầu của BV</p>
        </>
      ),
    },
    {
      name: 'ĐÀ NẴNG',
      address: '',
      phone: '',
      time: '',
      isHeader: true,
    },
    {
      name: 'Bệnh viện Đà Nẵng',
      address: '103 Quang Trung, TP Đà Nẵng',
      phone: '0236 3885 117',
      time: (
        <>
          <p>Thứ 2-6: 7h30-11h30, 13h30-17h00</p>
        </>
      ),
    },
    {
      name: 'Bệnh viện ĐK Hoàn Mỹ Đà Nẵng (*)',
      address: '161 Nguyễn Văn Linh, quận Thanh Khê, TP Đà Nẵng',
      phone: '0236 3650 272',
      time: (
        <>
          <p>Thứ 2-7: 7h30-18h00</p>
          <p>Đóng tạm ứng theo yêu cầu của BV</p>
        </>
      ),
    },
    {
      name: 'Bệnh viện Tâm Trí Đà Nẵng (*)',
      address: '64 Cách Mạng Tháng Tám, P.Khuê Trung, Q.Cẩm Lệ, TP Đà Nẵng',
      phone: '0236 3679 555',
      time: (
        <>
          <p>Thứ 2-7: 7h-11h30, 12h30-16h</p>
        </>
      ),
    },
    {
      name: 'Trung tâm chuẩn đoán y khoa kỹ thuật cao Thiện Nhân - Đà Nẵng (*)',
      address: '112 Hoàng Đức Lương, Phường An Hải Bắc, Quận Sơn Trà, TP Đà Nẵng',
      phone: '0236 3828 489',
      time: (
        <>
          <p>Thứ 2-CN: 7h-11h30, 12h30-18h</p>
        </>
      ),
    },
    {
      name: 'HUẾ',
      address: '',
      phone: '',
      time: '',
      isHeader: true,
    },
    {
      name: 'Trung tâm Điều trị theo yêu cầu và Quốc tế - Bệnh viện Trung ương Huế',
      address: '3 Ngô Quyền, Phường Vĩnh Ninh, Thành phố Huế',
      phone: '0234 3890 888',
      time: (
        <>
          <p>Thứ 2-7: 7h-17h00</p>
          <p>Đóng tạm ứng theo yêu cầu của BV</p>
        </>
      ),
    },
    {
      name: 'QUÃNG NGÃI',
      address: '',
      phone: '',
      time: '',
      isHeader: true,
    },
    {
      name: 'Nha Khoa Sài Gòn Quãng Ngãi (*)',
      address: '706 Quang Trung, P.Chánh Lộ, TP.Quãng Ngãi',
      phone: (
        <>
          <p>0255 383 7222</p>
          <p>0255 383 7799</p>
        </>
      ),
      time: (
        <>
          <p>Thứ 2-7: 7h00-19h00</p>
          <p>Chủ nhật: 7h300-17h00</p>
        </>
      ),
    },
  ];

  const nhaKhoaTuChoiPhiaBac = [
    {
      name: 'Nha khoa Việt Hàn',
      address: '199A Lò Đức. Phạm Đình Hổ, Hai Bà Trung',
    },
    {
      name: 'Nha khoa Minh Tâm',
      address: '1B Trần Hưng Đạo, Hai Bà Trưng Hà Nội',
    },
    {
      name: 'Nha khoa Mỹ',
      address: '11. Của Nam, Phường Của Nam, Quận Hoàn Kiếm, Hà Nội',
    },
    {
      name: 'Nha khoa Việt Mỹ',
      address: '61. Trường Chinh, Phường Phương Liệt, Quận Thanh Xuân, Hà Nội',
    },
    {
      name: 'Nha khoa Mỹ',
      address: '70 Nguyễn Chí Thanh, Láng Thượng, Đông Đa, Hà Nội',
    },
    {
      name: 'Phòng khám răng hàm mặt Việt Xô',
      address: '108 Lương Thế Vinh, Quận Thanh Xuân, Hà Nội',
    },
    {
      name: 'Nha khoa Happy Smiles Land',
      address: '25A1 Trần Duy Hưng, Cầu Giấy, Hà Nội',
    },
    {
      name: 'Nha khoa Sài Gòn',
      address: '208 Trung Kinh P. Yên Hòa, Q. Cầu Giấy, Hà Nội',
    },
    {
      name: 'Nha khoa Osaka',
      address: 'VP3 bán đảo Linh Đàm, Nguyễn Duy Trinh, Quận Hoàng Mai, Hà Nội',
    },
    {
      name: 'Phòng khám nha khoa Sài Gòn',
      address: '376 Trần Khát Chân, Quận Hai Bà Trưng, Hà Nội',
    },
    {
      name: 'Phòng khám đa khoa Minh Tâm',
      address: '1B, Trần Hưng Đạo, Phường Bạch Đằng Quận Hai Bà Trưng Hà Nội',
    },
    {
      name: 'Nha khoa Phạm Dương',
      address: 'Tầng 5, 52 Bà Triệu, Hoàn Kiếm, Hà Nội',
    },
    {
      name: 'Nha khoa quốc tế Nguyễn Khoa',
      address: 'Số 05 Nguyễn Du, Bùi Thị Xuân, Hai Bà Trưng, Hà Nội',
    },
    {
      name: 'Nha khoa Nhật Tân',
      address: '525 Au Co Tây Hồ, Hà Nội',
    },
    {
      name: 'Nha khoa 68',
      address: 'A6 TT10 Nguyễn Khuyến, Khu đô thị Văn Quán, Hà Đông, Hà Nội',
    },
    {
      name: 'Nha khoa Smile One',
      address: '167 Phố Quan Hoa, Cầu Giấy, Hà Nội',
    },
    {
      name: 'Nha khoa NISO',
      address: '291 Đường Nguyễn Khang Yên Hoà, Cầu Giấy, Hà Nội',
    },
    {
      name: 'Nha khoa Việt Pháp',
      address: '459 Lê Duẩn - Thanh Khê - Thành Phố Đà Nẵng',
    },
    {
      name: 'Nha Khoa Minh Ngọc - Beauty Dental Clinic',
      address: '617 Núi Thành, Hải Châu, Đà Nẵng',
    },
  ];

  const nhaKhoaTuChoiPhiaNam = [
    {
      name: 'Nha khoa Pháp Việt - Bình Dương',
      address: '34 Đường Cách Mạng Tháng 8, Phường Chánh Nghĩa, TP Thủ Dầu Một, Bình Dương',
    },
    {
      name: 'Nha khoa Việt Mỹ',
      address: '526 Đại Lộ Bình Dương, Phường Hiệp Thành, TP Thủ Dầu Một, Bình Dương',
    },
    {
      name: 'Nha Khoa Nguyên Hùng',
      address: '175 Nguyễn thượng hiền F6 - Quận Bình thạnh DT 02862853799',
    },
    {
      name: 'Nha Khoa Smileland',
      address: '488 Liên Phường - Phước Long B - TP Thủ Đức - TPHCM',
    },
    {
      name: 'Nha Khoa Bảo Việt',
      address: '909A Huỳnh Tấn Phát, Khu phố 2, phường Phú Thuận, quận 7, Tp HCM',
    },
  ];

  const benhVienTuChoiPhiaBac = [
    {
      name: 'PK chuyên khoa Tai Mũi Họng',
      address: '367 Đường Giải Phón - HBT-HN',
    },
    {
      name: 'PK chuyên khoa Tai Mũi Họng',
      address: 'Số 33/38 Phương Mai- Đống Đa, HN',
    },
    {
      name: 'PK Tai mũi họng chất lượng cao',
      address: '116 H2 Thành Công, Ba Đình, Hà Nội',
    },
    {
      name: 'Phòng khám chuyên khoa YHCT Dasarang (Công ty TNHH DASARANG MEDICAL VIỆT NAM)',
      address:
        'Tầng 2 khối A tòa nhà Sông Đà, số 18 đường Phạm Hùng, Phường Mỹ Đình 1, Quận Nam Từ Liêm, Thành phố Hà Nội, Việt Nam',
    },
  ];

  const benhVienTuChoiPhiaNam = [
    {
      name: 'Phòng khám Đa khoa Hồng Cường',
      address: '87 - 89 Thành Thái F 14 - Quận 10',
    },
    {
      name: 'Phòng khám đa khoa Quốc Tế',
      address: '221 Nguyễn Thị Minh Khai - Phường Nguyễn Cư Trinh - q1 TP HCM',
    },
    {
      name: 'PK Đa Khoa Văn Kiệt',
      address: '646-648 Võ Văn kiệt -F1 - Quận 5, Tp HCM',
    },
    {
      name: 'Phòng khám đa khoa Âu Á',
      address: '425 Nguyễn Văn Luông - F12 - Quận 6, Tp HCM',
    },
    {
      name: 'Phòng khám đa khoa Thăng Long',
      address: '575 Sư Vạn Hạnh-F12-Quận 10, Tp HCM',
    },
    {
      name: 'PK Đa khoa Đinh Tiên Hoàng',
      address: '34 Đinh Tiên Hoàng - Đakao - Quận 1, Tp HCM',
    },
    {
      name: 'Phòng khám đa khoa Đại Đông',
      address: '461 Cộng Hòa- F 13 Q Tân Bình, Tp HCM',
    },
    {
      name: 'Phòng khám đa khoa Hoàn Cầu',
      address: '80 đường Châu Văn Liêm - F11 - Quận 5, Tp HCM',
    },
    {
      name: 'PK ĐK Nam Việt TPHCM',
      address: '202 Tô Hiến Thành - F15 - Quận 10, Tp HCM',
    },
    {
      name: 'CTY TNHH Trung tâm y tế Hà Đô',
      address: '35B - 35 C đường 3/2 F11 - Quận 10, Tp HCM',
    },
    {
      name: 'PK đa khoa Nguyễn Trãi Thủ Dầu Một',
      address: '303 đại lộ Bình Dương - Phường Chánh Nghĩa TP TDM - Bình Dương',
    },
    {
      name: 'Phòng khám đa khoa Thế Kỷ Mới',
      address: '133A Trần Hưng Đạo - P.An Phú - Quận Ninh kiều - TP Cần Thơ',
    },
    {
      name: 'Phòng khám đa khoa Nam Bộ',
      address: '134/1 Tô Hiến thành - P15 - Quận 10, Tp HCM',
    },
    {
      name: 'Phòng khám Nam khoa Đại Việt',
      address: '1505 đường 3 tháng 2, Phường 16, Quận 11, TPHCM',
    },
    {
      name: 'Phòng khám Đa Khoa Đại Tín',
      address: '306 Đại Lộ Bình Dương. Khu phố 1, P Phú Hòa, Tp Thủ Dầu Một, Tỉnh Bình Dương',
    },
    {
      name: 'Phòng khám đa khoa Đại Y',
      address: '274 Hải Thượng Lãn Ông, Phường 14, Quận 5, TP. HCM',
    },
  ];

  const nhaThuocTuChoi = [
    {
      name: 'Nhà thuốc Tân Bình 4',
      address: '498 Trường Chinh, Phường 13, Tân Bình',
    },
    {
      name: 'Nhà thuốc Mỹ Châu',
      address: 'Tất cả các chi nhánh/cơ sở',
    },
    {
      name: 'Nhà thuốc Maika',
      address: '95/471 Phạm Văn Thuận, Phường Tân Mai, Biên Hòa, Đồng Nai',
    },
  ];

  const chungTuBoiThuong = [
    {
      title: 'NẰM VIỆN NỘI TRÚ, ĐIỀU TRỊ TRONG NGÀY',
      list: [
        {
          name: 'Giấy yêu bồi thường của PVI, điền đầy đủ thông tin',
        },
        {
          name: 'Biên bản tường trình tai nạn (trong trường hợp tai nạn',
        },
        {
          name: 'Chỉ định và kết quả các xét nghiệm, chẩn đoán, chụp X-quang, chụp CT',
        },
        {
          name: 'Giấy chứng nhận phẫu thuật (nếu có)',
        },
        {
          name: 'Giấy chứng sinh hoặc Giấy khai sinh trong trường hợp sinh con',
        },
        {
          name: 'Giấy ra viện',
        },
        {
          name: 'Toa thuốc có chữ ký của bác sĩ',
        },
        {
          name: 'Hoá đơn VAT chi phí điều trị (bản gốc):',
        },
        {
          name: 'Hóa đơn VAT hoặc phiếu thu tiền thuốc (bản gốc)',
          list: [
            'Bảng kê chi tiết viện phí (Nếu trên hóa đơn không kê cụ thể các chi phí/ tên thuốc thì phải có bảng kê chi tiết đi kèm)',
          ],
        },
      ],
    },
    {
      title: 'ĐIỀU TRỊ NGOẠI TRÚ',
      list: [
        {
          name: 'Giấy yêu cầu bồi thường của PVI, điền đầy đủ thông tin',
        },
        {
          name: 'Biên bản tường trình tai nạn (trong trường hợp tai nạn)',
        },
        {
          name: 'Sổ khám bệnh/ toa thuốc có chuẩn đoán/ Giấy chứng nhận khám chữa bệnh',
        },
        {
          name: 'Chỉ định và kết quả các xét nghiệm, chẩn đoán, chụp X-quang, chụp CT',
        },
        {
          name: 'Trường hợp chữa tủy phải có hình chụp chân răng trước khi điều trị (*)',
        },
        {
          name: 'Toa Thuốc có chữ ký bác sĩ .',
        },
        {
          name: 'Hoá đơn VAT hoặc phiếu thu khám chữa bệnh (bản gốc)',
          list: [
            'Hóa đơn VAT hoặc phiếu thu tiền thuốc (bản gốc)',
            'Bảng kê chi tiết viện phí (Nếu trên hóa đơn không kê cụ thể các chi phí/ tên thuốc thì phải có bảng kê chi tiết đi kèm)',
          ],
        },
      ],
    },
    {
      title: 'HÓA ĐƠN VAT',
      list: [
        {
          name: 'Tổng giá trị thanh toán các phiếu thu ≤ 200,000 VNĐ => Hóa đơn VAT hoặc hóa đơn bán lẻ.',
        },
        {
          name: 'Tổng giá trị thanh toán các phiếu thu > 200,000 VNĐ => Bắt buộc phải có Hóa đơn VAT',
        },
      ],
    },
  ];

  const danhSachNhaThuocXuatHoaDonVAT = [
    {
      title: 'HÀ NỘI',
      list: [
        'Số 1 Phương Mai,Đống Đa',
        'Số 40 Phương Mai',
        '48 Hai Bà Trưng',
        'Ngự Bình,Lầu B ,Đống Đa',
        '625 Hoàng Hoa Thám',
        '33B Văn Miếu',
      ],
    },
    {
      title: 'HÀ NỘI',
      list: [
        'Số 20 Giải Phóng',
        'Số 229 Giải Phóng',
        'Số 69 Trần Xuân Soạn',
        'Số 102 Thái Thịnh',
        'Số 02 Hàng Bài',
        '46 Ngõ 164 Vương Thừa Vũ',
        'Số 3 Trần Hưng Đạo',
      ],
    },
    {
      title: 'ĐỒNG NAI',
      list: [
        '32A/4 QL 15, KP8, Tam Hiệp',
        '4 Đường 30/4,BV Đồng Nai',
        '221B QL15,Biên Hòa',
        '95/471 QL15, Biên Hòa',
        '77 Phan Đình Phùng',
      ],
    },
    {
      title: 'TP.HCM',
      list: [
        '243 Hai Bà Trưng,Q.1',
        '284 Cống Quỳnh, Q.1',
        '119 Lý Tự Trọng, Q.1',
        '202 Nguyễn Cư Trinh,Q.1',
        '389 Hai Bà Trưng,Q.3',
        '408 Hai Bà Trưng,Q.3',
        '410 Hai Bà Trưng,Q.3',
        '155B Trần Quốc Thảo, Q.3',
        '124 Trần Quốc Thảo,Q.3',
        '128 Trần Quốc Thảo,Q.3',
      ],
    },
    {
      title: 'TP.HCM',
      list: [
        '280 Điện Biên Phủ, Q.3',
        '338 Lê Văn Sỹ, Q.3',
        '02 Nguyễn Thông,Q.3',
        '510 Lê Văn Sỹ, Q.3',
        '929 Trần Hưng Đạo, Q.5',
        '615A Trần Hưng Đạo, Q.5',
        '201 Nguyễn Chí Thanh, Q.5',
        '27 Châu Văn Liêm, Q.5',
        '215 Hồng Bàng, Q.5',
        '334-336 Ngô Gia Tự,Q.10',
        '254 Hòa Hảo, Q.10',
      ],
    },
    {
      title: 'TP.HCM',
      list: [
        '520 Nguyễn Tri Phương,Q.10',
        '220/4 Ông Ích Khiêm,Q.10',
        '18-20 Nguyễn Trường Tộ,Q.4',
        '72 Bạch Đằng,Q.Bình Thạnh',
        '03 Nơ Trang Long,Q.Bình Thạnh',
        '01 Tô Ngọc Vân, Q.Thủ Đức',
        '4/10 Trần Văn Hứa,Q.Thủ Đức',
        '212 Lê Đức Thọ, Q.Gò Vấp',
        '11 Nguyễn Kiệm,Q.Gò Vấp ',
      ],
    },
  ];

  const thucPhamChucNang = [
    {
      name: 'THUỐC',
      number: (
        <>
          <p>V 1234-09</p>
          <p>VD 1234-10</p>
          <p>VN 1234-11</p>
        </>
      ),
      sign: (
        <>
          <p>Thuốc được đăng ký tại cục quản lý dược</p>
          <p>SĐK có chữ V, tiếp theo là số thứ tự & năm đăng ký</p>
        </>
      ),
    },
    {
      name: 'THỰC PHẨM CHỨC NĂNG',
      number: '1234/YT-CNTC',
      sign: 'Thực phẩm chức năng được đăng ký tại cục An toàn vệ sinh thực phẩm ,SĐK có chữ YT-CNTC (chứng nhận tiêu chuẩn)',
    },
    {
      name: 'MỸ PHẨM',
      number: (
        <>
          <p>1234/CBMP</p>
          <p>1234/LHMP</p>
          <p>1234/CBMP-HN</p>
        </>
      ),
      sign: 'Mỹ phẩm được đăng ký tại sở y tế địa phương có chữ CBMP. Công Bố Mỹ Phẩm hoặc LHMP: Liên Hiệp Mỹ Phẩm (HN: Hà Nội)',
    },
  ];

  const danhSachThacMac = [
    {
      question: '1) Thế nào là bệnh?',
      answer: {
        text: [
          'Ốm đau, bệnh tật: tình trạng sức khỏe bất thường so với tình trạng sức khỏe thông thường.',
        ],
      },
    },
    {
      question: '2) Những bệnh gì được xem là bệnh nghề nghiệp?',
      answer: {
        text: [
          'Bệnh nghề nghiệp được qui định bởi Luật lao động và Danh mục Bệnh nghề nghiệp được nêu ra trong Thông tư của Bộ Y Tế và Bộ Lao Động và Thương binh xã hội và Quyết định số 27/2006/QĐ-BYT ngày 21 tháng 9 năm 2006 của Bộ Y Tế bao gồm:',
        ],
        list: [
          'Nhiễm độc chì và các hợp chất chì',
          'Nhiễm độc Benzen và các đồng đẳng',
          'Nhiễm độc thủy ngân và các hợp chất thủy ngân',
          'Bụi phổi do Silic',
          'Bụi phổi do Amăng',
          'Nhiễm độc Mănggan và các hợp chất của Mănggan',
          'Nhiễm các tia phóng xạ và tia X',
          'Bệnh điếc nghề nghiệp do tiếng ồn',
          'Loét da, loét vách ngăn mũi, viêm da, chàm tiếp xúc',
          'Bệnh xạm da nghề nghiệp',
          'Bệnh bụi phổi bông',
          'Bệnh lap nghề nghiệp',
          'Bệnh viêm gan do Virut nghề nghiệp',
          'Bệnh nhiễm độc TNT (Trinitrotoluen)',
          'Bệnh nhiễm độc Asen và các hợp chất Asen nghề nghiệp',
          'Bệnh nhiễm độc Nicotin nghề nghiệp',
          'Bệnh nhiễm độc hóa chất trừ sâu nghề nghiệp',
          'Bệnh giảm áp mãn tính nghề nghiệp',
          'Bệnh viêm chế quản mạn tính nghề nghiệp',
          'Bệnh hen phế quản nghề nghiệp',
          'Bệnh nhiễm độc cacbonmonoxit nghề nghiệp',
          'Bệnh nốt dầu nghề nghiệp',
          'Bệnh viêm loét da, viêm móng, xung quanh móng nghề nghiệp',
        ],
      },
    },
    {
      question:
        '3) Bảo hiểm y tế và Bảo hiểm PVI Care khác nhau như thế nào? Có thể được yêu cầu bồi thường ở cả 2 nơi?',
      answer: {
        list: [
          'Vận chuyển cấp cứu sẽ được bồi thường theo quyền lợi của hợp đồng nếu hợp đồng có tham gia phần này và khi đấy là vận chuyển cấp cứu khẩn cấp hợp lý (phải nguy kịch đến tính mạng) đến phòng khám hoặc nơi điều trị gần nhất với chứng từ thanh toán hợp lệ.',
          'Chi phí y tế: Người được bảo hiểm có thể được bồi thường chi phí y tế từ một hoặc nhiều hợp đồng bảo hiểm khác nhau, nhưng số tiền được bồi thường không nhiều hơn tổng chi phí y tế đã bỏ ra (dựa trên chứng từ thanh toán hợp lý theo quy định tài chính).',
        ],
      },
    },
    {
      question:
        '4) Trong trường hợp cấp cứu, chi phí xe cứu thương để vận chuyển người được bảo hiểm đến bệnh viện có được bồi thường không? Nếu vận chuyển cấp cứu bằng những phương tiện khác thuận tiện hơn thì có được bồi thường không?',
      answer: {
        text: [
          'Vận chuyển cấp cứu sẽ được bồi thường theo quyền lợi của hợp đồng nếu hợp đồng có tham gia phần này và khi đấy là vận chuyển cấp cứu khẩn cấp hợp lý (phải nguy kịch đến tính mạng) đến phòng khám hoặc nơi điều trị gần nhất với chứng từ thanh toán hợp lệ.',
        ],
      },
    },
    {
      question: '5) Tôi nên khám ngoại trú ở đâu để được thanh toán?',
      answer: {
        text: [
          'Bạn được tự do lựa chọn nơi khám và điều trị phù hợp tại tất cả các cơ sở y tế có giấy phép hoạt động tại Việt Nam và có thể xuất hóa đơn tài chính cho anh chị đối với chi phí y tế trên VND 200,000.',
        ],
      },
    },
    {
      question: '6) Tôi có bị hạn chế số lần khám ngoại trú mỗi năm hay không?',
      answer: {
        text: ['Chương trình bảo hiểm của bạn không hạn chế số lần khám 1 năm.'],
      },
    },
    {
      question:
        '7) Tôi có thể được bồi thường khi đi khám, chữa tại bệnh viện Việt Pháp hay Comlumbia - Gia Định không?',
      answer: {
        text: [
          'Có, bạn sẽ được bồi thường khi đi khám, chữa bệnh ngoại trú hay nội trú tại hai bệnh viện này hoặc tất cả các bệnh viện khác có giấy phép hoạt động tại Việt Nam với điều kiện chi phí khám, chữa bệnh không vượt quá giới hạn quyền lợi bảo hiểm trên hợp đồng.',
          'Khi bạn đi khám chữa bệnh ở hai bệnh viện này, luôn nhớ yêu cầu nhân viên bệnh viện cung cấp các chứng từ sau để thực hiện thanh toán:',
        ],
        list: [
          'Chứng nhận bệnh lý có chữ kí của bác sĩ và dấu của bệnh viện (Medical Report/ Medical Certificate)',
          'Toa thuốc có chữ kí bác sĩ và dấu của bệnh viện',
          'Hóa đơn tài chính có dấu của bệnh viện, tên và chữ kí người thu tiền.',
          'Nếu hóa đơn thuốc chưa ghi rõ tên thuốc và liều lượng, vui lòng yêu cầu bệnh viện cung cấp OPD Charges (là một chứng từ thể hiện chi tiết thuốc đã mua)',
        ],
        text2: [
          'Khi bạn đi khám chữa bệnh ở hai bệnh viện này, luôn nhớ yêu cầu nhân viên bệnh viện cung cấp các chứng từ sau để thực hiện thanh toán:',
        ],
        list2: [
          'Chứng nhận bệnh lý có chữ kí của bác sĩ và dấu của bệnh viện (Medical Report/ Medical Certificate)',
          'Toa thuốc có chữ kí bác sĩ và dấu của bệnh viện',
          'Hóa đơn tài chính có dấu của bệnh viện, tên và chữ kí người thu tiền.',
          'Nếu hóa đơn thuốc chưa ghi rõ tên thuốc và liều lượng, vui lòng yêu cầu bệnh viện cung cấp OPD Charges (là một chứng từ thể hiện chi tiết thuốc đã mua)',
        ],
      },
    },
    {
      question:
        '8) Tôi có thể được bồi thường khi khám, chữa bệnh đông y hay khám, chữa bệnh tại phòng khám y khoa Trung Quốc?',
      answer: {
        list: [
          'Có, bạn sẽ được bồi thường khi đi khám chữa bệnh tại hai nơi này, với điều kiện chi phí khám, chữa bệnh không vượt quá giới hạn quyền lợi bảo hiểm trên hợp đồng',
          'Tất cả hóa đơn, chứng từ, toa thuốc, phải được thể hiện bằng tiếng Việt. Nếu hóa đơn, chứng từ bằng tiếng Hoa, hãy yêu cầu nơi khám chữa bệnh dịch sang tiếng Việt',
          'Nhà bảo hiểm khuyến cáo bạn nên khám chữa bệnh tại các Bệnh Viện/ Viện Y Học cổ truyền dân tộc. Những nơi có thể cung cấp dịch vụ khám chữa bệnh tốt và có đầy đủ chứng từ tôi yêu cầu.',
        ],
      },
    },
    {
      question: '9) Nếu bệnh cần điều trị dài hạn tôi sẽ gởi hồ sơ như thế nào?',
      answer: {
        text: [
          'Bạn có thể chờ điều trị xong sau đó thanh toán thành một lần tuy nhiên không vượt qua thời hạn bảo hiểm quy định nộp hồ sơ bồi thường là 180 ngày kể từ ngày hoàn tất việc điều trị. Lưu ý, bạn nên có giấy hẹn, chỉ định tái khám của bác sĩ cho những lần khám chữa kế tiếp.',
          'Nhằm giúp bạn theo dõi quá trình khám chữa bệnh của mình, bạn có thể giữ lại sổ khám bệnh gốc sau khi đã nộp cho nhà bảo hiểm bản photo có đóng dấu treo của công ty để xác nhận sao y bản chính.',
        ],
      },
    },
    {
      question: '10) Chi phí kiểm tra sức khỏe có được bồi thường không?',
      answer: {
        text: [
          'Không. Hợp đồng bảo hiểm sức khỏe chỉ bảo hiểm cho việc điều trị bệnh tật, ốm đau, thai sản.',
        ],
      },
    },
    {
      question:
        '11) Nếu tôi không thể đến bệnh viện hoặc các trung tâm y tế để khám bệnh được mà phải mời các bác sĩ đến nhà khám thì có được bồi thường không?',
      answer: {
        text: [
          'Không. Hợp đồng bảo hiểm không bảo hiểm cho việc điều trị tại nhà mà chỉ xem xét giải quyết cho việc điều trị tại các cơ sở y tế hoạt động hợp pháp.',
        ],
      },
    },
    {
      question: '12) Tôi có thể xin lại sổ khám bệnh/ giấy ra viện bản gốc?',
      answer: {
        text: [
          'Trong trường hợp cần thiết, Người được bảo hiểm có thể giữ lại bản gốc các chứng từ điều trị (toa thuốc, sổ khám bệnh, giấy ra viện,…) hoặc giấy nghỉ hưởng BHXH, chỉ cần gởi bản copy. Tuy nhiên, công ty bảo hiểm có thể yêu cầu cung cấp bản chính trong trường hợp cần thiết.',
        ],
      },
    },
    {
      question: '13) Trám răng thẩm mỹ có được bồi thường không?',
      answer: {
        text: [
          'Không. Chỉ có trám răng bằng amalgam hoặc composit mới được bồi thường theo quyền lợi chăm sóc răng cơ bản.',
        ],
      },
    },
    {
      question:
        '14) Nếu bác sĩ tư bán luôn thuốc và ghi tổng số tiền lên toa thuốc, tôi có được thanh toán không?',
      answer: {
        text: [
          'Theo quy định của pháp luật, bác sĩ Tây y không được phép bán thuốc nên toa thuốc do bác sĩ Tây y bán sẽ không được bảo hiểm chi trả. Ngược lại, bác sĩ Đông y được quyền bán thuốc với điều kiện xuất được hóa đơn hợp lệ.',
          'Còn tiền khám bệnh sẽ được Công ty bảo hiểm chi trả nếu bệnh đó thuộc phạm vi bảo hiểm của Hợp đồng bảo hiểm và khách cung cấp được hóa đơn hợp lệ.',
        ],
      },
    },
    {
      question:
        '15) Nếu tôi không có địa chỉ e-mail để liên hệ, tôi có thể sử dụng số điện thoại của tôi để liên hệ?',
      answer: {
        text: [
          'Địa chỉ e-mail cần phải có để chúng tôi liên hệ với bạn để gửi thông tin hoặc yêu cầu bổ sung đầy đủ thông tin, giấy tờ cần thiết, tránh bị thiếu sót & không đúng',
          'Bạn có thể sử dụng địa chỉ e-mail của bất kì ai mà bạn có thể mượn để sử dụng và cần ghi lưu ý trên giấy yêu cầu bồi thường là đề nghị chuyển thông tin đến bạn để bồi thường được biết.',
        ],
      },
    },
  ];

  return (
    <section>
      {showsScrolBtn && (
        <div
          id="scrolToTop"
          onClick={() => {
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth',
            });
          }}
          style={{
            position: 'fixed',
            bottom: '60px',
            right: '60px',
            color: 'gray',
            textAlign: 'center',
            lineHeight: 0,
            textDecoration: 'none',
            borderRadius: '50%',
            backgroundColor: 'white',
            zIndex: '100',
          }}
        >
          <StyledButton />
        </div>
      )}

      <Container>
        <ContainerPink></ContainerPink>
        <ContainerPurple></ContainerPurple>
        <ContainerImg>
          <img src={BgrContainer} alt="background container" />
        </ContainerImg>
        <ContainerTitle>
          <Title>{t('PTIManagement:claim_insurance.title')}</Title>
          <SubTitle>{t('PTIManagement:claim_insurance.description')}​</SubTitle>
        </ContainerTitle>
      </Container>
      <Collapse
        accordion
        collapsible="header"
        style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}
      >
        <CustomPanel
          header={
            <div>
              <CollapseTitle>Quyền lợi bảo hiểm</CollapseTitle>
            </div>
          }
          key="1"
        >
          <Collapse
            accordion
            bordered={false}
            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
          >
            <Panel
              header={<CollapseSmallTitle>Điều kiện tham gia bảo hiểm</CollapseSmallTitle>}
              key="1"
            >
              <div>
                {dieuKienThamGiaBH.map((ele: any) => {
                  return (
                    <div>
                      <p style={{ fontWeight: 'bold', marginBottom: '10px' }}>{ele.title}</p>
                      <ul
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          paddingLeft: '30px',
                          gap: '10px',
                        }}
                      >
                        {ele.list.map((item: any) => {
                          return <li style={{ listStyle: 'disc', color: 'black' }}>{item.text}</li>;
                        })}
                      </ul>
                    </div>
                  );
                })}
              </div>
            </Panel>

            <Panel header={<CollapseSmallTitle>Các quyền lợi bảo hiểm</CollapseSmallTitle>} key="2">
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {quyenLoiBaoHiem.map((ele: any) => {
                  return (
                    <div
                      style={{
                        width: '32%',
                        backgroundColor: '#B4C7E7',
                        borderRadius: '20px',
                        padding: '20px',
                      }}
                    >
                      <p
                        style={{
                          textAlign: 'center',
                          fontWeight: 'bold',
                          fontSize: '16px',
                          marginBottom: '10px',
                        }}
                      >
                        {ele.title}
                      </p>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '15px',
                          alignItems: 'center',
                        }}
                      >
                        {ele.list.map((item: any) => {
                          return (
                            <p
                              style={{
                                backgroundColor: '#396CC5',
                                color: 'white',
                                width: '70%',
                                borderRadius: '10px',
                                textAlign: 'center',
                                padding: '10px',
                              }}
                            >
                              {item.text}
                            </p>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </Panel>

            <Panel header={<CollapseSmallTitle>Các loại trừ chung</CollapseSmallTitle>} key="3">
              <div>
                <ul
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    paddingLeft: '30px',
                    gap: '10px',
                  }}
                >
                  {loaiTruChung.map((ele: any) => {
                    return <li style={{ listStyle: 'disc', color: 'black' }}>{ele}</li>;
                  })}
                </ul>

                <p style={{ fontWeight: 'bold', fontSize: '20px', textDecoration: 'underline' }}>
                  PHẦN 1 - BẢO HIỂM TAI NẠN
                </p>
                <p>
                  Bảo hiểm trường hợp tử vong, thương tật thân thể do tai nạn, trừ những trường hợp
                  loại trừ bảo hiểm theo quy tác Bảo hiểm hiện hành
                </p>
                <SectionTable>
                  <table className="listHospital">
                    <tr>
                      <th style={{ width: '5%' }}>STT</th>
                      <th style={{ width: '45%' }}>QUYỀN LỢI BẢO HIỂM</th>
                      <th style={{ width: '25%', textAlign: 'center' }}>
                        <p>SỐ TIỀN BẢO HIỂM</p> <p>(VND)</p> <p>CHƯƠNG TRÌNH 1</p>
                      </th>
                      <th style={{ width: '25%', textAlign: 'center' }}>
                        <p>SỐ TIỀN BẢO HIỂM</p> <p>(VND)</p> <p>CHƯƠNG TRÌNH 2</p>
                      </th>
                    </tr>
                    {baoHiemTaiNan.map((item) => {
                      return (
                        <tr>
                          <td>{item.stt}</td>
                          <td style={item.isBold ? { fontWeight: 'bold' } : {}}>{item.name1}</td>
                          <td
                            style={
                              item.isBold
                                ? { fontWeight: 'bold', textAlign: 'center' }
                                : { textAlign: 'center' }
                            }
                          >
                            {item.name2}
                          </td>
                          <td
                            style={
                              item.isBold
                                ? { fontWeight: 'bold', textAlign: 'center' }
                                : { textAlign: 'center' }
                            }
                          >
                            {item.name3}
                          </td>
                        </tr>
                      );
                    })}
                  </table>
                </SectionTable>

                <p
                  style={{
                    fontWeight: 'bold',
                    fontSize: '20px',
                    textDecoration: 'underline',
                    marginTop: '10px',
                  }}
                >
                  CÁC LOẠI TRỪ ĐỐI VỚI TỬ VONG DO BỆNH TẬT, THAI SẢN
                </p>

                <ul style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                  {loaiTruTuVongBenhVaThaiSan.map((ele) => {
                    return <li>{ele}</li>;
                  })}
                </ul>

                <p style={{ fontWeight: 'bold', fontSize: '20px', textDecoration: 'underline' }}>
                  PHẦN 2 - BẢO HIỂM TỬ VONG/ TÀN TẬT TOÀN BỘ VĨNH VIỄN DO ỐM ĐAU, BỆNH TẬT, THAI SẢN
                </p>
                <p>
                  Bảo hiểm trường hợp tử vong hoặc tàn tật toàn bộ vĩnh viễn do ốm đau, bệnh tật trừ
                  những điểm loại trừ bảo hiểm theo quy tắc BH hiện hành
                </p>
                <SectionTable>
                  <table className="listHospital">
                    <tr>
                      <th style={{ width: '5%' }}>STT</th>
                      <th style={{ width: '45%' }}>QUYỀN LỢI BẢO HIỂM</th>
                      <th style={{ width: '25%', textAlign: 'center' }}>NHÂN VIÊN</th>
                      <th style={{ width: '25%', textAlign: 'center' }}>NHÂN VIÊN</th>
                    </tr>
                    {baoHiemTuVong.map((item) => {
                      return (
                        <tr>
                          <td>{item.stt}</td>
                          <td>{item.name1}</td>
                          <td style={{ textAlign: 'center' }}>{item.name2}</td>
                          <td style={{ textAlign: 'center' }}>{item.name3}</td>
                        </tr>
                      );
                    })}
                  </table>
                </SectionTable>

                <div style={{ paddingLeft: '20px' }}>
                  <p
                    style={{
                      fontWeight: 'bold',
                      fontSize: '20px',
                      textDecoration: 'underline',
                      marginTop: '10px',
                    }}
                  >
                    PHẦN 2A - BẢO HIỂM CHI PHÍ Y TẾ DO ỐM ĐAU, BỆNH TẬT THAI SẢN
                  </p>
                  <p>
                    Bảo hiểm trường hợp ốm đau, bệnh tật, thai sản phải nằm viện hoặc phẫu thuật,
                    trừ những trường hợp loại trừ bảo hiểm
                  </p>
                  <SectionTable>
                    <table className="listHospital">
                      <tr>
                        <th style={{ width: '5%' }}>STT</th>
                        <th style={{ width: '45%' }}>QUYỀN LỢI BẢO HIỂM</th>
                        <th style={{ width: '25%', textAlign: 'center' }}>
                          <p>SỐ TIỀN BẢO HIỂM</p> <p>VND</p>
                        </th>
                        <th style={{ width: '25%', textAlign: 'center' }}>
                          <p>SỐ TIỀN BẢO HIỂM</p> <p>VND</p>
                        </th>
                      </tr>
                      {baoHiemChiPhiYTe.map((item) => {
                        return (
                          <tr>
                            <td>{item.stt}</td>
                            <td>{item.name1}</td>
                            <td style={{ textAlign: 'center' }}>{item.name2}</td>
                            <td style={{ textAlign: 'center' }}>{item.name3}</td>
                          </tr>
                        );
                      })}
                    </table>
                  </SectionTable>

                  <p
                    style={{
                      fontWeight: 'bold',
                      fontSize: '20px',
                      textDecoration: 'underline',
                      marginTop: '10px',
                    }}
                  >
                    PHẦN 2B - ĐIỀU TRỊ NGOẠI TRÚ VÀ CHĂM SÓC RĂNG
                  </p>

                  <SectionTable>
                    <table className="listHospital">
                      <tr>
                        <th style={{ width: '5%' }}>STT</th>
                        <th style={{ width: '45%' }}>QUYỀN LỢI BẢO HIỂM</th>
                        <th style={{ width: '25%', textAlign: 'center' }}>
                          <p>SỐ TIỀN BẢO HIỂM</p> <p>VND</p>
                        </th>
                        <th style={{ width: '25%', textAlign: 'center' }}>
                          <p>SỐ TIỀN BẢO HIỂM</p> <p>VND</p>
                        </th>
                      </tr>
                      {baoHiemNgoaiTruVaRang.map((item) => {
                        return (
                          <tr>
                            <td>{item.stt}</td>
                            <td>{item.name1}</td>
                            <td style={{ textAlign: 'center' }}>{item.name2}</td>
                            <td style={{ textAlign: 'center' }}>{item.name3}</td>
                          </tr>
                        );
                      })}
                    </table>
                  </SectionTable>

                  <div style={{ marginTop: '20px' }}>
                    <p style={{ fontWeight: 'bold', textDecoration: 'underline' }}>Lưu ý: </p>
                    <ul
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                        paddingLeft: '30PX',
                      }}
                    >
                      {luuYDieuTriRang.map((ele) => {
                        return <li>{ele}</li>;
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </Panel>

            <Panel header={<CollapseSmallTitle>Thời gian chờ</CollapseSmallTitle>} key="4">
              <ul
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px',
                  paddingLeft: '30px',
                }}
              >
                {thoiGianCho.map((ele: any) => {
                  return <li style={{ listStyle: 'disc', fontWeight: 'bold' }}>{ele}</li>;
                })}
              </ul>

              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <b style={{ fontSize: '16px' }}>Đối với Nhân viên</b>
                <SectionTable style={{ width: '100%' }}>
                  <table className="listHospital">
                    <tr>
                      <th style={{ width: '70%' }}>Tai nạn</th>
                      <th style={{ width: '30%', textAlign: 'center' }}>Không áp dụng</th>
                    </tr>
                    {thoiGianChoNhanVien.map((item) => {
                      return (
                        <tr>
                          <td>{item.name}</td>
                          <td style={{ textAlign: 'center' }}>{item.apply}</td>
                        </tr>
                      );
                    })}
                  </table>
                </SectionTable>

                <b style={{ fontSize: '16px', marginTop: '20px' }}>Đối với Người thân</b>
                <SectionTable style={{ width: '100%' }}>
                  <table className="listHospital">
                    <tr>
                      <th style={{ width: '70%' }}>Tai nạn</th>
                      <th style={{ width: '30%', textAlign: 'center' }}>Không áp dụng</th>
                    </tr>
                    {thoiGianChoNguoiThan.map((item) => {
                      return (
                        <tr>
                          <td>{item.name}</td>
                          <td style={{ textAlign: 'center' }}>{item.apply}</td>
                        </tr>
                      );
                    })}
                  </table>
                </SectionTable>
              </div>
            </Panel>

            <Panel header={<CollapseSmallTitle>Một số định nghĩa</CollapseSmallTitle>} key="5">
              <SectionSpecialDisease>
                <CardCustom title="Bệnh có sẵn">
                  <b>
                    <u>Bệnh có sẵn:</u>
                  </b>{' '}
                  là các bệnh có từ trước ngày bắt đầu tham gia bảo hiểm và do bệnh này,
                  <ul
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      paddingLeft: '30px',
                      gap: '10px',
                    }}
                  >
                    <li style={{ listStyle: 'disc' }}>
                      Người được bảo hiểm đã được điều trị trong vòng 3 năm trước ngày bắt đầu bảo
                      hiểm hoặc,
                    </li>
                    <li style={{ listStyle: 'disc' }}>
                      Có triệu chứng về mặt y khoa bất kể là người được bảo hiểm đã được khám, điều
                      trị hay chưa
                    </li>
                    <li style={{ listStyle: 'disc' }}>
                      Các bệnh sau: Viêm Amidan cần phải cắt, viêm VA cần phải nạo, vẹo vách ngăn
                      cần phẫu thuật, rối loạn tiền đình, thoái hóa khớp, đốt sống, viêm tai giữa
                      cần phẫu thuật, thoát vị đĩa đệm, bệnh hen.
                    </li>
                  </ul>
                </CardCustom>
                <CardCustom title="Bệnh đặc biệt">
                  <b>
                    <u>Bệnh đặc biệt:</u>
                  </b>{' '}
                  ung thư, u bướu các loại, huyết áp, tim mạch, loét dạ dày, viêm khớp mãn tính,
                  loét ruột, viêm gan, viêm màng trong dạ con, trĩ, sỏi các loại trong hệ thống tiết
                  niệu và đường mật, đục thủy tinh thể, viêm xoang, tiểu đường, Parkinson, bệnh liên
                  quan đến hệ thống tạo máu
                </CardCustom>
              </SectionSpecialDisease>
            </Panel>

            <Panel
              header={<CollapseSmallTitle>Một số loại trừ đối với bệnh</CollapseSmallTitle>}
              key="6"
            >
              <ul
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px',
                  paddingLeft: '30px',
                }}
              >
                {loaiTruDoiVoiBenh.map((ele) => {
                  return <li style={{ listStyle: 'disc' }}>{ele}</li>;
                })}
              </ul>
            </Panel>
          </Collapse>
        </CustomPanel>

        <CustomPanel
          header={
            <div>
              <CollapseTitle>Quy trình bồi thường</CollapseTitle>
            </div>
          }
          key="2"
        >
          <Collapse
            accordion
            bordered={false}
            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
          >
            <Panel
              header={<CollapseSmallTitle>1. Hình thức bão lãnh trực tiếp</CollapseSmallTitle>}
              key="1"
            >
              <div>
                Hình thức bão lãnh trực tiếp (chỉ áp dụng bảo lãnh khi điều trị tại bệnh viện có
                trong danh sách bảo lãnh PVI):
              </div>
              <ul
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px',
                  paddingLeft: '30px',
                }}
              >
                <li style={{ listStyle: 'disc' }}>
                  Điều trị ở các bệnh viên có trong danh sách bảo lãnh của PVI
                </li>
                <li style={{ listStyle: 'disc' }}>Xuất trình CMND và thẻ bảo hiểm PVI</li>
              </ul>

              <div style={{ fontStyle: 'italic', fontWeight: 'bold' }}>
                Lưu ý : Không áp dụng bảo lãnh cho các trường hợp : tai nạn , cấp cứu, lễ tết và
                ngoài giờ hành chính
              </div>

              <p
                style={{
                  marginBottom: '10px',
                  marginTop: '30px',
                  textAlign: 'center',
                  fontWeight: 'bold',
                  fontSize: '20px',
                }}
              >
                BẢO LÃNH VIỆN PHÍ
              </p>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {baoLanhVienPhi.map((ele) => {
                  return (
                    <div style={{ width: '32%' }}>
                      <p
                        style={{
                          backgroundColor: '#4472C4',
                          color: 'white',
                          borderRadius: '5px',
                          padding: '10px',
                          fontWeight: 'bold',
                          textAlign: 'center',
                        }}
                      >
                        {ele.name}
                      </p>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '20px',
                          marginTop: '20px',
                          borderRadius: '5px',
                        }}
                      >
                        {ele.list.map((item) => {
                          return (
                            <p
                              style={{
                                backgroundColor: '#D3D9EC',
                                padding: '20px 10px',
                                textAlign: 'center',
                                borderRadius: '5px',
                              }}
                            >
                              {item}
                            </p>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>

              <p
                style={{
                  marginTop: '30px',
                  textAlign: 'center',
                  fontWeight: 'bold',
                  fontSize: '20px',
                }}
              >
                THẺ PVI CARE
              </p>
              <p
                style={{
                  marginBottom: '30px',
                  textAlign: 'center',
                }}
              >
                ( Được gửi vào địa chỉ email của mỗi cá nhân )
              </p>
              <div style={{ margin: '0 auto', display: 'flex', justifyContent: 'center' }}>
                <img style={{ width: '500px' }} src={PVI_Insurance_card} alt="card PVI Insurance" />
              </div>

              <p
                style={{
                  marginTop: '30px',
                  textAlign: 'center',
                  fontWeight: 'bold',
                  fontSize: '20px',
                }}
              >
                DANH SÁCH BẢO LÃNH VIỆN PHÍ
              </p>
              <p>
                Cập nhật mới nhất tại{' '}
                <a href="https://pvicare.vn/danh-sach-benh-vien">
                  Danh sách các bệnh viện bảo lãnh
                </a>
              </p>
              <SectionTable style={{ width: '100%' }}>
                <table className="listHospital">
                  {benhVienBaoLanh.map((item) => {
                    return (
                      <tr>
                        <td
                          style={
                            item.isHeader
                              ? {
                                  width: '25%',
                                  backgroundColor: '#FCD5B4',
                                  textAlign: 'center',
                                  fontWeight: 'bold',
                                }
                              : { width: '25%' }
                          }
                        >
                          {item.name}
                        </td>
                        <td
                          style={
                            item.isHeader
                              ? { width: '30%', backgroundColor: '#FCD5B4', textAlign: 'center' }
                              : { width: '30%' }
                          }
                        >
                          {item.address}
                        </td>
                        <td
                          style={
                            item.isHeader
                              ? { width: '15%', backgroundColor: '#FCD5B4', textAlign: 'center' }
                              : { width: '15%', textAlign: 'center' }
                          }
                        >
                          {item.phone}
                        </td>
                        <td
                          style={
                            item.isHeader
                              ? { width: '30%', backgroundColor: '#FCD5B4', textAlign: 'center' }
                              : { width: '30%', textAlign: 'center' }
                          }
                        >
                          {item.time}
                        </td>
                      </tr>
                    );
                  })}
                </table>
              </SectionTable>

              <p style={{ marginTop: '30px', fontWeight: 'bold' }}>
                <i>(*) Lưu ý: PVI áp dụng chi trả cho điều trị răng như sau: </i>
              </p>
              <ul
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px',
                  paddingLeft: '30px',
                }}
              >
                <li style={{ listStyle: 'disc' }}>
                  Tại các khu vực: TP Hà Nội và TP Hồ Chí Minh: PVI chỉ chi trả cho khách hàng khi
                  khách hàng khám và điều trị nha khoa tại các cơ sở y tế Công lập và hoặc các cơ sở
                  y tế trong hệ thống bảo lãnh của PVI.
                </li>
                <li style={{ listStyle: 'disc' }}>
                  Đối với các địa bàn khác: Mở rộng chi trả tại các cơ sở y tế có giấy phép hoạt
                  động hợp pháp nhưng loại trừ các cơ sở y tế trong danh sách từ chối chi trả của BH
                  PVI.
                </li>
              </ul>

              <p style={{ marginTop: '30px', fontWeight: 'bold', textAlign: 'center' }}>
                DANH SACH CÁC NHA KHOA TỪ CHỐI CHI TRẢ BẢO HIỂM
              </p>

              <div
                style={{
                  border: '1px solid #ddd',
                  textAlign: 'center',
                  padding: '5px 0',
                  fontWeight: 'bold',
                }}
              >
                Khu vực phía Bắc
              </div>
              <div
                style={{
                  border: '1px solid #ddd',
                  backgroundColor: '#02AEF8',
                  color: 'white',
                  textAlign: 'center',
                }}
              >
                NHA KHOA
              </div>
              <SectionTable style={{ width: '100%', margin: '0' }}>
                <table className="listHospital">
                  <tr>
                    <th
                      style={{
                        width: '5%',
                        backgroundColor: '#D9E2F3',
                        padding: '0',
                        color: 'black',
                        textAlign: 'center',
                      }}
                    >
                      STT
                    </th>
                    <th
                      style={{
                        width: '40%',
                        backgroundColor: '#D9E2F3',
                        padding: '0',
                        color: 'black',
                        textAlign: 'center',
                      }}
                    >
                      Tên
                    </th>
                    <th
                      style={{
                        width: '55%',
                        backgroundColor: '#D9E2F3',
                        padding: '0',
                        color: 'black',
                        textAlign: 'center',
                      }}
                    >
                      Địa chỉ
                    </th>
                  </tr>

                  {nhaKhoaTuChoiPhiaBac.map((item, index) => {
                    return (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{item.name}</td>
                        <td>{item.address}</td>
                      </tr>
                    );
                  })}
                </table>
              </SectionTable>

              <div
                style={{
                  border: '1px solid #ddd',
                  textAlign: 'center',
                  padding: '5px 0',
                  fontWeight: 'bold',
                }}
              >
                Khu vực phía Nam
              </div>
              <div
                style={{
                  border: '1px solid #ddd',
                  backgroundColor: '#02AEF8',
                  color: 'white',
                  textAlign: 'center',
                }}
              >
                NHA KHOA
              </div>
              <SectionTable style={{ width: '100%', margin: '0' }}>
                <table className="listHospital">
                  <tr>
                    <th
                      style={{
                        width: '5%',
                        backgroundColor: '#D9E2F3',
                        padding: '0',
                        color: 'black',
                        textAlign: 'center',
                      }}
                    >
                      STT
                    </th>
                    <th
                      style={{
                        width: '40%',
                        backgroundColor: '#D9E2F3',
                        padding: '0',
                        color: 'black',
                        textAlign: 'center',
                      }}
                    >
                      Tên
                    </th>
                    <th
                      style={{
                        width: '55%',
                        backgroundColor: '#D9E2F3',
                        padding: '0',
                        color: 'black',
                        textAlign: 'center',
                      }}
                    >
                      Địa chỉ
                    </th>
                  </tr>

                  {nhaKhoaTuChoiPhiaNam.map((item, index) => {
                    return (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{item.name}</td>
                        <td>{item.address}</td>
                      </tr>
                    );
                  })}
                </table>
              </SectionTable>

              <div
                style={{
                  border: '1px solid #ddd',
                  textAlign: 'center',
                  padding: '5px 0',
                  fontWeight: 'bold',
                }}
              >
                Khu vực phía Bắc
              </div>
              <div
                style={{
                  border: '1px solid #ddd',
                  backgroundColor: '#02AEF8',
                  color: 'white',
                  textAlign: 'center',
                }}
              >
                BỆNH VIỆN/PHÒNG KHÁM
              </div>
              <SectionTable style={{ width: '100%', margin: '0' }}>
                <table className="listHospital">
                  <tr>
                    <th
                      style={{
                        width: '5%',
                        backgroundColor: '#D9E2F3',
                        padding: '0',
                        color: 'black',
                        textAlign: 'center',
                      }}
                    >
                      STT
                    </th>
                    <th
                      style={{
                        width: '40%',
                        backgroundColor: '#D9E2F3',
                        padding: '0',
                        color: 'black',
                        textAlign: 'center',
                      }}
                    >
                      Tên
                    </th>
                    <th
                      style={{
                        width: '55%',
                        backgroundColor: '#D9E2F3',
                        padding: '0',
                        color: 'black',
                        textAlign: 'center',
                      }}
                    >
                      Địa chỉ
                    </th>
                  </tr>

                  {benhVienTuChoiPhiaBac.map((item, index) => {
                    return (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{item.name}</td>
                        <td>{item.address}</td>
                      </tr>
                    );
                  })}
                </table>
              </SectionTable>

              <div
                style={{
                  border: '1px solid #ddd',
                  textAlign: 'center',
                  padding: '5px 0',
                  fontWeight: 'bold',
                }}
              >
                Khu vực phía Nam
              </div>
              <div
                style={{
                  border: '1px solid #ddd',
                  backgroundColor: '#02AEF8',
                  color: 'white',
                  textAlign: 'center',
                }}
              >
                BỆNH VIỆN/PHÒNG KHÁM
              </div>
              <SectionTable style={{ width: '100%', margin: '0' }}>
                <table className="listHospital">
                  <tr>
                    <th
                      style={{
                        width: '5%',
                        backgroundColor: '#D9E2F3',
                        padding: '0',
                        color: 'black',
                        textAlign: 'center',
                      }}
                    >
                      STT
                    </th>
                    <th
                      style={{
                        width: '40%',
                        backgroundColor: '#D9E2F3',
                        padding: '0',
                        color: 'black',
                        textAlign: 'center',
                      }}
                    >
                      Tên
                    </th>
                    <th
                      style={{
                        width: '55%',
                        backgroundColor: '#D9E2F3',
                        padding: '0',
                        color: 'black',
                        textAlign: 'center',
                      }}
                    >
                      Địa chỉ
                    </th>
                  </tr>

                  {benhVienTuChoiPhiaNam.map((item, index) => {
                    return (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{item.name}</td>
                        <td>{item.address}</td>
                      </tr>
                    );
                  })}
                </table>
              </SectionTable>

              <div
                style={{
                  border: '1px solid #ddd',
                  backgroundColor: '#02AEF8',
                  color: 'white',
                  textAlign: 'center',
                }}
              >
                NHÀ THUỐC
              </div>
              <SectionTable style={{ width: '100%', margin: '0' }}>
                <table className="listHospital">
                  <tr>
                    <th
                      style={{
                        width: '5%',
                        backgroundColor: '#D9E2F3',
                        padding: '0',
                        color: 'black',
                        textAlign: 'center',
                      }}
                    >
                      STT
                    </th>
                    <th
                      style={{
                        width: '40%',
                        backgroundColor: '#D9E2F3',
                        padding: '0',
                        color: 'black',
                        textAlign: 'center',
                      }}
                    >
                      Tên
                    </th>
                    <th
                      style={{
                        width: '55%',
                        backgroundColor: '#D9E2F3',
                        padding: '0',
                        color: 'black',
                        textAlign: 'center',
                      }}
                    >
                      Địa chỉ
                    </th>
                  </tr>

                  {nhaThuocTuChoi.map((item, index) => {
                    return (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{item.name}</td>
                        <td>{item.address}</td>
                      </tr>
                    );
                  })}
                </table>
              </SectionTable>
            </Panel>

            <Panel
              header={
                <CollapseSmallTitle>
                  2. Hình thức thanh toán trước, lấy hóa đơn về bồi thường sau
                </CollapseSmallTitle>
              }
              key="2"
            >
              <p>
                Hình thức thanh toán trước, lấy hóa đơn về bồi thường sau : ( Hình thức này áp dụng
                cho trường hợp khám ngoại trú và điều trị tại các bệnh viện nằm ngoài danh sách bảo
                lãnh PVI )
              </p>
              <ul
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px',
                  paddingLeft: '30px',
                  marginTop: '20px',
                }}
              >
                <li style={{ listStyle: 'disc' }}>
                  Các chứng từ cần thiết gửi yêu cầu bồi thường . Lưu ý : Hóa đơn cần nộp bản gốc -
                  Chứng từ y tế có thể nộp bản photo
                </li>
                <li style={{ listStyle: 'disc' }}>
                  Điền thông tin vào Giấy yều cầu trả tiền bảo hiểm
                </li>
                <li style={{ listStyle: 'disc' }}>
                  Biên bản tường trình tai nạn (trường hợp bị tai nạn)
                </li>
                <li style={{ listStyle: 'disc' }}>
                  Thời hạn nộp hồ sơ bồi thường: trong vòng 60 ngày kể từ ngày kết thúc điều trị.
                  (nên nộp hồ sơ ngay hoặc không nên quá 7 - 10 ngày sau khi kết thúc điều trị).
                </li>
                <li style={{ listStyle: 'disc' }}>
                  Hóa đơn VAT phải được xuất trong vòng 30 ngày kể từ ngày mua hoặc sử dụng dịch vụ
                  (nên xuất hóa đơn ngay trong ngày kết thúc điều trị).
                </li>
                <li style={{ listStyle: 'disc' }}>
                  Danh sách nhà thuốc, phòng khám, nha khoa từ chối.
                </li>
              </ul>
              <p style={{ fontWeight: 'bold' }}>
                <i>
                  Lưu ý : sau khi gửi hồ sơ bồi thường đến trung tâm bồi thường, nhờ các anh chị lưu
                  lại bill, mã vận đơn của thư để khi cần kiểm tra có thể tra được thư từ bill hoặc
                  mã vận đơn của đơn vị chuyển phát nhanh
                </i>
              </p>

              <p
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  fontSize: '20px',
                  marginTop: '30px',
                }}
              >
                CÁC CHỨNG TỪ BỒI THƯỜNG
              </p>

              {chungTuBoiThuong.map((ele) => {
                return (
                  <div>
                    <p
                      style={{ backgroundColor: '#DBDBDB', padding: '2px 5px', fontWeight: 'bold' }}
                    >
                      {ele.title}
                    </p>
                    <ul
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                        paddingLeft: '30px',
                        marginTop: '20px',
                      }}
                    >
                      {ele.list.map((item) => {
                        return (
                          <>
                            <li style={{ listStyle: 'disc' }}>{item.name}</li>
                            {item.list?.map((text) => {
                              return <li>{text}</li>;
                            })}
                          </>
                        );
                      })}
                    </ul>
                  </div>
                );
              })}

              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <p
                  style={{
                    fontWeight: 'bold',
                    fontSize: '24px',
                    textAlign: 'center',
                    marginTop: '30px',
                  }}
                >
                  MẪU KẾT QUẢ XÉT NGHIỆM
                </p>
                <img src={imagePVI} alt="img PVI" style={{ width: '750px' }} />
                <p
                  style={{
                    fontWeight: 'bold',
                    fontSize: '24px',
                    textAlign: 'center',
                    marginTop: '30px',
                  }}
                >
                  TOA THUỐC HÓA ĐƠN THUỐC
                </p>
                <img src={imagePVI1} alt="img PVI 1" style={{ width: '750px' }} />
                <p
                  style={{
                    fontWeight: 'bold',
                    fontSize: '24px',
                    textAlign: 'center',
                    marginTop: '30px',
                  }}
                >
                  HÓA ĐƠN DƯỚI 200.000 VNĐ
                </p>
                <img src={imagePVI2} alt="img PVI 2" style={{ width: '750px' }} />
                <p
                  style={{
                    fontWeight: 'bold',
                    fontSize: '24px',
                    textAlign: 'center',
                    marginTop: '30px',
                  }}
                >
                  HÓA ĐƠN TỪ 200.000 VNĐ TRỞ LÊN
                </p>
                <img src={imagePVI3} alt="img PVI 3" style={{ width: '750px' }} />
              </div>
            </Panel>

            <Panel
              header={<CollapseSmallTitle>3. Hình thức bồi thường qua app</CollapseSmallTitle>}
              key="3"
            >
              <ul
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px',
                  paddingLeft: '30px',
                  marginTop: '20px',
                }}
              >
                <li style={{ listStyle: 'disc' }}>Đăng nhập vào app PVI</li>
                <li style={{ listStyle: 'disc' }}>
                  Làm theo hướng dẫn sử dụng app ở file hướng dẫn sử dụng app
                </li>
              </ul>
              <p style={{ fontWeight: 'bold' }}>
                <i>
                  Lưu ý : Hình thức bồi thường qua app vẫn sẽ có 1 vài hồ sơ hoặc trường hợp đặc
                  biệt cần gửi hồ sơ bảng cứng cho PVI. Vậy nên nhờ các anh chị sau khi nộp qua app
                  vẫn giữ hồ sơ bệnh án bản cứng phòng trường hợp PVI yêu cầu gửi bảng cứng
                </i>
              </p>
              <p style={{ fontWeight: 'bold' }}>
                <i>
                  App PVI nếu tự động đăng xuất ra ngoài, hoặc có bất kì vấn đề gì về app, vui lòng
                  cập nhật ứng dụng và đăng nhập lại thử lần nữa.
                </i>
              </p>

              <p
                style={{
                  textAlign: 'center',
                  fontWeight: 'bold',
                  fontSize: '24px',
                  marginTop: '30px',
                }}
              >
                DANH SÁCH CÁC NHÀ THUỐC CÓ THỂ XUẤT HÓA ĐƠN VAT
              </p>
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                  marginBottom: '70px',
                }}
              >
                {danhSachNhaThuocXuatHoaDonVAT.map((ele) => {
                  return (
                    <div style={{ width: '33%', border: '1px solid white' }}>
                      <p
                        style={{
                          backgroundColor: '#4472C4',
                          color: 'white',
                          textAlign: 'center',
                          fontSize: '20px',
                        }}
                      >
                        {ele.title}
                      </p>
                      <div
                        style={{ backgroundColor: '#CFD5EA', height: '100%', paddingLeft: '5px' }}
                      >
                        {ele.list.map((item) => {
                          return <p>{item}</p>;
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>

              <p
                style={{
                  textAlign: 'center',
                  fontWeight: 'bold',
                  fontSize: '24px',
                }}
              >
                CÁCH NHẬN BIẾT THUỐC, MỸ PHẨM, THỰC PHẨM CHỨC NĂNG
              </p>

              <SectionTable style={{ width: '100%', margin: '0' }}>
                <table className="listHospital">
                  <tr>
                    <th
                      style={{
                        width: '30%',
                        backgroundColor: '#4472C4',
                        color: 'white',
                        textAlign: 'center',
                      }}
                    >
                      SẢN PHẨM
                    </th>
                    <th
                      style={{
                        width: '20%',
                        backgroundColor: '#4472C4',
                        color: 'white',
                        textAlign: 'center',
                      }}
                    >
                      SỐ ĐĂNG KÝ
                    </th>
                    <th
                      style={{
                        width: '50%',
                        backgroundColor: '#4472C4',
                        color: 'white',
                        textAlign: 'center',
                      }}
                    >
                      DẤU HIỆU NHẬN BIẾT
                    </th>
                  </tr>

                  {thucPhamChucNang.map((item) => {
                    return (
                      <tr>
                        <td>{item.name}</td>
                        <td>{item.number}</td>
                        <td>{item.sign}</td>
                      </tr>
                    );
                  })}
                </table>
              </SectionTable>
            </Panel>
          </Collapse>
        </CustomPanel>

        <CustomPanel
          header={
            <div>
              <CollapseTitle>Thông tin chung về công ty bảo hiểm PVI</CollapseTitle>
            </div>
          }
          key="3"
        >
          <Collapse
            accordion
            bordered={false}
            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
          >
            <Panel header={<CollapseSmallTitle>Hotline</CollapseSmallTitle>} key="1">
              <div style={{ paddingLeft: '30px' }}>
                <ul style={{ margin: '0' }}>
                  <li style={{ listStyle: 'disc' }}>
                    <b>NGUYỄN THỊ MỸ TUYÊN</b>
                  </li>
                </ul>
                <p>
                  <b>CÔNG TY BẢO HIỂM PVI GIA ĐỊNH</b>
                </p>
                <ul style={{ marginTop: '30px', marginBottom: '0' }}>
                  <li style={{ listStyle: 'disc' }}>
                    <b>Address:</b> Tầng 3, Cao Ốc Yoco, 41 Nguyễn Thị Minh Khai, Phường Bến Nghé,
                    Quận 1, Tp.HCM
                  </li>
                </ul>
                <p>
                  <b>Phone :</b> 0938 796 388
                </p>
                <p>
                  <b>E-mail :</b> tuyenntm@pvi.com.vn
                </p>
              </div>
            </Panel>
            <Panel
              header={<CollapseSmallTitle>Thông tin gửi hồ sơ bồi thường</CollapseSmallTitle>}
              key="2"
            >
              <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                <p>
                  <b>VP Phía Bắc:</b>
                </p>
                <p>
                  Người Nhận : Phòng chi trả trực tiếp - Văn phòng đại diện CSKH phía Bắc bảo hiểm
                  PVI
                </p>
                <p>
                  Địa chỉ : Tầng 19 Tòa nhà PVI Tower, số 1 Phạm Văn Bạch, phường Yên Hòa, quận Cầu
                  Giấy, thành phố Hà Nội
                </p>
                <p>Điện thoại: (024) 3768 2999 Fax: (024) 3795 5625</p>
              </div>
              <div
                style={{ display: 'flex', flexDirection: 'column', gap: '10px', marginTop: '30px' }}
              >
                <p>
                  <b>VP Phía Nam:</b>
                </p>
                <p>
                  Người Nhận : Phòng chi trả trực tiếp - Văn phòng đại diện CSKH phía Nam bảo hiểm
                  PVI
                </p>
                <p>
                  Địa chỉ : Tầng 1 tòa nhà PV Gas, 673 Nguyễn Hữu Thọ, huyện Nhà Bè, thành phố Hồ
                  Chí Minh
                </p>
                <p>Điện thoại: (028) 6290.9118 - Fax: (028) 6290.9108</p>
              </div>
            </Panel>
            <Panel header={<CollapseSmallTitle>Giải đáp thắc mắc</CollapseSmallTitle>} key="3">
              <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                {danhSachThacMac.map((ele) => {
                  return (
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                      <p style={{ fontWeight: 'bold', marginBottom: '5px' }}>{ele.question}</p>
                      {ele.answer?.text &&
                        ele.answer.text.map((item) => {
                          return <p>{item}</p>;
                        })}
                      {ele.answer?.list && (
                        <ul
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '10px',
                            paddingLeft: '30px',
                          }}
                        >
                          {ele.answer.list.map((item) => {
                            return <li style={{ listStyle: 'disc' }}>{item}</li>;
                          })}
                        </ul>
                      )}
                      {ele.answer?.text2 &&
                        ele.answer.text2.map((item) => {
                          return <p>{item}</p>;
                        })}
                      {ele.answer?.list2 && (
                        <ul
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '10px',
                            paddingLeft: '30px',
                          }}
                        >
                          {ele.answer.list2.map((item) => {
                            return <li style={{ listStyle: 'disc' }}>{item}</li>;
                          })}
                        </ul>
                      )}
                    </div>
                  );
                })}
              </div>
            </Panel>
          </Collapse>
        </CustomPanel>
      </Collapse>
      {/* </Space> */}
    </section>
  );
};

export default PageXclaimInsurance;

export const CustomButton = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: none;
`;
export const StyledButton = styled(UpCircleOutlined)`
  font-size: 40px;
  color: var(--cl_info500);
  cursor: pointer;
`;
export const CustomPanel = styled(Collapse.Panel)`
  .ant-collapse-header {
    background-color: #f3f2f2;
  }
  .ant-collapse {
    font-size: 16px;
  }
  p {
    margin: 0;
  }
`;
export const Container = styled.div`
  margin: 15px 0;
  width: 100%;
  height: 300px;
  position: relative;
  p {
    margin: 0;
  }
`;
export const ContainerPink = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 92%;
  height: 85%;
  background-color: #f85982;
`;
export const ContainerPurple = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 92%;
  height: 85%;
  margin: 0 0 20px 40px;
  background-color: #7d35b3;
`;
export const ContainerImg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 92%;
  height: 85%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`;
export const ContainerTitle = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 500px;
  height: 200px;
  background-color: white;
  padding-left: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
`;
export const Title = styled.div`
  font-size: 35px;
  font-weight: bold;
`;
export const SubTitle = styled.div`
  font-size: 18px;
  color: #774774;
`;
export const CollapseTitle = styled.div`
  font-size: 24px;
  color: black;
  font-weight: bold;
`;
export const CollapseSmallTitle = styled.div`
  font-size: 18px;
  color: black;
`;

export const SectionTable = styled.div`
  margin-top: 20px;
  p {
    margin: 0;
  }
  .title_section {
    font-size: 32px;
    color: black;
    font-weight: bold;
  }
  .text_section {
    font-size: 18px;
    color: black;
  }
  .listHospital {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  .listHospital td,
  .listHospital th {
    border: 1px solid #ddd;
    padding: 8px;
  }

  .listHospital tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  .listHospital tr:hover {
    background-color: #ddd;
  }

  .listHospital th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #48086f;
    color: white;
  }
`;

export const SectionSpecialDisease = styled.div`
  margin-top: 20px;
  p {
    margin: 0;
  }
  .title_section {
    font-size: 32px;
    color: black;
    font-weight: bold;
  }
`;

export const CardCustom = styled(Card)`
  .ant-card-head {
    background-color: #48086f;
    font-size: 22px;
    color: white;
    border-radius: 10px;
  }
  .ant-card-body {
    font-size: 18px;
  }
`;
