import { DeleteTwoTone, EditTwoTone } from '@ant-design/icons';
import ITVTable from 'components/ITVTable';
import MenuAction from 'components/MenuAction';
import { MODE } from 'constants/types';
import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ContentCell from './ContentCell';

const PerformanceWeightTable = (props: any) => {
  const { t } = useTranslation(['performance', 'insurance', 'action']);
  const {
    control,
    error,
    getValues,
    setValue,
    section,
    data,
    isMainResponse,
    setMode,
    setCurrentItem,
    mainResponsibilities,
    deleteGoals,
    isEdit,
  } = props;

  const [columns, setColumns] = useState<any>([]);
  const [tableData, setTableData] = useState<any[]>([]);

  const MENU = [
    {
      name: t('insurance:edit'),
      icon: <EditTwoTone twoToneColor="#F1592A" />,
      handleClick: (value: any) => {
        setMode(MODE.EDIT);
        const selectedValue = mainResponsibilities.filter(
          (ele: any) => ele.goal === value.goalName,
        )[0];
        setCurrentItem(selectedValue);
      },
    },
    {
      name: t('action:delete'),
      icon: <DeleteTwoTone twoToneColor="#B7B7B7" />,
      type: 'delete',
      handleClick: (value: any) => {
        const selectedValue = mainResponsibilities.filter(
          (ele: any) => ele.goal === value.goalName,
        )[0];
        setCurrentItem(selectedValue);
        deleteGoals(selectedValue);
      },
    },
  ];

  useEffect(() => {
    if (data?.length) {
      // Extract unique goal names and use them as row identifiers
      const goals = Array.from(
        new Set(data?.flatMap((item: any) => item?.step?.map((s: any) => s?.goalName))),
      );
      // Generate columns dynamically
      const col = [
        {
          title: t('performance:goals'),
          key: 'goalName',
          dataIndex: 'goalName',
          width: 200,
          align: 'center',
        },
        ...data
          ?.map((i: any, idx: any) => ({ ...i, indexNew: idx }))
          ?.filter((field: any) => field?.isShow)
          .map((field: any, index: any) => ({
            title: field?.stepName,
            key: field?.stepName,
            dataIndex: field?.stepName,
            width: 200,
            align: 'center',
            render: (_: any, record: any) => {
              const step = field?.step?.find((s: any) => s?.goalName === record?.goalName) || {};
              return (
                <Controller
                  name={`${section}.data[${field?.indexNew}].step[${goals.indexOf(
                    record?.goalName,
                  )}]`}
                  control={control}
                  render={({ field: f }) => (
                    <ContentCell
                      editParent={`${section}.data[${field?.indexNew}].isEdit`}
                      rating={step.point}
                      content={step.commoment}
                      setValue={setValue}
                      nameRating={`${section}.data[${field?.indexNew}].step[${goals?.indexOf(
                        record?.goalName,
                      )}].point`}
                      nameContent={`${section}.data[${field?.indexNew}].step[${goals?.indexOf(
                        record?.goalName,
                      )}].commoment`}
                      disabled={!field?.allowEdit}
                    />
                  )}
                />
              );
            },
          })),
      ];

      if (isMainResponse) {
        col.push({
          title: t('action:action'),
          dataIndex: 'action',
          align: 'center',
          key: 'action',
          width: 50,
          minWidth: 50,
          render: function (text: any, record: any, index: any) {
            return <MenuAction menu={MENU} data={record} />;
          },
        });
      }

      // Prepare table data
      const tableData = goals.map((goal: any) => {
        const row: any = { goalName: goal };
        data
          .filter((field: any) => field?.isShow)
          .forEach((field: any) => {
            const step = field.step.find((s: any) => s.goalName === goal) || {};
            row[field.stepName] = {
              rating: step.point,
              content: step.commoment,
            };
          });
        return row;
      });

      setColumns(col);
      setTableData(tableData);
    }
  }, [data, control, section, setValue, t]);

  return <ITVTable data={tableData} columns={columns} isRowSelect={false} showFooter={false} />;
};

export default PerformanceWeightTable;
